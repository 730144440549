import { call, put } from 'redux-saga/effects';

import * as docsApi from '../api';
import * as docsActions from '../actions';

function* getDocsByIds({ payload }) {
  yield put(docsActions.setUploadDocsLoading(true));
  try {
    const response = yield call(docsApi.getDocumentsByIds, payload);

    yield put(docsActions.setUploadDocs(response.data));
  } catch (error) {
    console.error(error);
  }
  yield put(docsActions.setUploadDocsLoading(false));
}

export default getDocsByIds;
