import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/v1/project`;

class ProjectService {
  create({ companyId, ...data }) {
    return axios.post(`${API_URL}/company/${companyId}`, data).then(({ data }) => data);
  }

  getAllByCompanyId(companyId) {
    return axios.get(`${API_URL}/company/${companyId}/all`).then(({ data }) => data);
  }

  getForFilter(companyId) {
    return axios.get(`${API_URL}/company/${companyId}/filter`).then(({ data }) => data);
  }

  updateById(projectId, data) {
    return axios.patch(`${API_URL}/${projectId}`, data).then(({ data }) => data);
  }

  getAllByUserCompanyIds(userId, companyId) {
    return axios.get(`${API_URL}/company/${companyId}/user/${userId}/all`).then(({ data }) => data);
  }

  getAllForUser(userId) {
    return axios.get(`${API_URL}/user/${userId}/all`).then(({ data }) => data);
  }
}

export default new ProjectService();
