import { call, put, select } from 'redux-saga/effects';

import * as clientsActions from '../actions';
import * as clientsApi from '../api';

function* getClients() {
  yield put(clientsActions.setLoading(true));
  try {
    const { page, pageSize, search, agencyCompanyName } = yield select(store => store.clients);
    const response = yield call(clientsApi.getClients, { page, pageSize, search, agencyCompanyName });

    yield put(clientsActions.setClients(response.data.clients));
    yield put(clientsActions.setTotal(response.data.count));
  } catch (error) {
    console.error(error);
  }
  yield put(clientsActions.setLoading(false));
}

export default getClients;
