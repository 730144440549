import { call, select, put } from 'redux-saga/effects';

import * as docsApi from '../api';
import * as docsActions from '../actions';

function* getSelectDocs() {
  yield put(docsActions.setSelectDocsLoading(true));
  try {
    const { company } = yield select(store => store.auth);
    const { user } = yield select(store => store.auth);
    const { page, pageSize } = yield select(store => store.docs);

    const response = yield call(docsApi.getDocuments, {
      page,
      size: pageSize,
      companyId: company ? company.id : user.companyId,
    });

    yield put(
      docsActions.setSelectDocs(
        response.data.docs.map(item => ({
          id: item.id,
          name: item.label,
          required: item.required,
        })),
      ),
    );
  } catch (error) {
    console.error(error);
  }
  yield put(docsActions.setSelectDocsLoading(false));
}

export default getSelectDocs;
