import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';

const HelpBlock = ({ title, children }) => (
  <Stack gap="0.25rem">
    {title && <Typography variant="H3">{title}</Typography>}
    {children && <Typography variant="SUBTITLE1">{children}</Typography>}
  </Stack>
);

HelpBlock.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default HelpBlock;
