import { call, put } from 'redux-saga/effects';

import * as constantsActions from '../actions';
import * as constantsApi from '../api';

export function* fetchCountries() {
  const response = yield call(constantsApi.getCountries);

  yield put(constantsActions.setCountries(response.data));
}
