import { createSelector } from '@reduxjs/toolkit';

const getAuthEntity = ({ chat }) => chat;

export const getShowChat = createSelector([getAuthEntity], chat => chat.show);

export const getMinimizeChat = createSelector([getAuthEntity], chat => chat.minimize);

export const getSocket = createSelector([getAuthEntity], chat => chat.socket);

export const getChatId = createSelector([getAuthEntity], chat => chat.chatId);

export const getChats = createSelector([getAuthEntity], chat => chat.chats);

export const getSendMessage = createSelector([getAuthEntity], chat => chat.sendMessage);
