import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import Draggable from 'react-draggable';

import useChat from 'src/hooks/useChat';
import * as chatSelectors from 'src/store/chat/selectors';
import * as chatActions from 'src/store/chat/actions';
import * as authSelectors from 'src/store/auth/selectors';
import useAction from 'src/utils/useAction';
import { LINEAR_SIZES } from 'src/constants/chat';
import Header from 'src/components/chat/Header/Header';
import Input from 'src/components/chat/Input/Input';
import MessageList from 'src/components/chat/MessageList/MessageList';
import ChatList from 'src/components/chat/ChatList/ChatList';
import { actions } from 'src/contexts/alert/context';

import { Container } from './ChatBox.style';

const ChatBox = () => {
  const minimizeChat = useSelector(chatSelectors.getMinimizeChat);
  const showChat = useSelector(chatSelectors.getShowChat);
  const chats = useSelector(chatSelectors.getChats);
  const chatId = useSelector(chatSelectors.getChatId);
  const sendMessage = useSelector(chatSelectors.getSendMessage);
  const user = useSelector(authSelectors.getUser);
  const [currentMessage, setCurrentMessage] = useState({});
  const [chatPosition, setChatPosition] = useState({
    x: 134,
    y: 210,
  });
  const container = useRef(null);
  const inputReferance = useRef();
  const photoInputReferance = useRef();
  const setChatId = useAction(chatActions.setChatId);

  const clearMessageList = () => setChatId(null);

  const clearRefs = () => {
    inputReferance.current.value = '';
    photoInputReferance.current.value = '';
    photoInputReferance.current.files = null;
    setCurrentMessage({});
  };

  const sendHandler = useCallback(() => {
    if (currentMessage.file || currentMessage.text.trim().length) {
      sendMessage(chatId, {
        ...currentMessage,
        type: currentMessage.file ? 'photo' : 'text',
      });

      clearRefs();
    }
  }, [chatId, currentMessage, sendMessage]);

  const photoHandler = () => {
    photoInputReferance.current.click();
  };

  const photoChangeHandler = useCallback(
    e => {
      if (e.target.files && e.target.files.length && e.target.files[0].type.includes('image')) {
        setCurrentMessage({
          ...currentMessage,
          file: {
            name: e.target.files[0].name,
            data: e.target.files[0],
          },
        });
      } else {
        actions.showAlert({
          type: 'warning',
          message: 'File type is not supported',
        });
      }

      e.target.value = '';
    },
    [currentMessage],
  );

  const inputChangeHandler = useCallback(() => {
    setCurrentMessage({
      ...currentMessage,
      text: inputReferance.current.value.trim(),
    });
  }, [currentMessage]);

  const stopDragHandle = () => {
    if (container.current) {
      const { x, y } = container.current.getBoundingClientRect();
      const translateY = container.current.style.transform.match(/[0-9-]+/gi)?.pop() || 0;

      localStorage.setItem('chatX', x);
      localStorage.setItem('chatY', y);
      localStorage.setItem('translateY', translateY);
    }
  };

  const getTopPosition = useCallback(() => {
    if (minimizeChat && container.current) {
      const translateY = localStorage.getItem('translateY');

      return document.body.clientHeight - LINEAR_SIZES.header - (translateY ? Number(translateY) : 0);
    }

    return chatPosition.y;
  }, [minimizeChat, chatPosition]);

  useChat();

  useEffect(() => {
    const savedX = localStorage.getItem('chatX');
    const savedY = localStorage.getItem('chatY');

    setChatPosition({
      x: savedX ? Number(savedX) : document.body.offsetWidth - 375,
      y: savedY ? Number(savedY) : 0,
    });
  }, []);

  useEffect(() => {
    if (currentMessage.file) {
      sendHandler();
    }
  }, [currentMessage?.file, sendHandler]);

  const content = useMemo(() => {
    const inputProps = {
      inputReferance,
      photoInputReferance,
      inputChangeHandler,
      sendHandler,
      photoHandler,
      photoChangeHandler,
    };

    if (chatId) {
      return (
        <Box
          sx={{
            height: minimizeChat ? '0px' : `${LINEAR_SIZES.body}px`,
            transition: 'all 0.3s',
            overflow: 'hidden',
          }}
        >
          <Grid
            item
            sx={{
              height: `${LINEAR_SIZES.list}px`,
            }}
          >
            <MessageList user={user} chat={chats[chatId]} />
          </Grid>
          <Grid
            item
            sx={{
              height: `${LINEAR_SIZES.input}px`,
            }}
          >
            <Input {...inputProps} />
          </Grid>
        </Box>
      );
    }

    return (
      <Grid
        item
        sx={{
          height: minimizeChat ? '0px' : `${LINEAR_SIZES.body}px`,
          transition: 'all 0.3s',
          overflow: 'hidden',
        }}
      >
        <ChatList user={user} chats={chats} setChatId={setChatId} />
      </Grid>
    );
  }, [chatId, chats, inputChangeHandler, photoChangeHandler, sendHandler, setChatId, user, minimizeChat]);

  if (!showChat || !user) return null;

  return (
    <Draggable handle="#draggable-chatbox-header" onStop={stopDragHandle} disabled={minimizeChat}>
      <Container
        ref={container}
        minimizeChat={minimizeChat}
        sx={{
          left: chatPosition.x,
          top: getTopPosition(),
        }}
      >
        <Grid container direction="column" alignItems="stretch">
          <Grid
            id="draggable-chatbox-header"
            item
            sx={{
              height: `${LINEAR_SIZES.header}px`,
            }}
          >
            <Header
              chat={chats[chatId]}
              user={user}
              back={() => {
                clearMessageList(chatId);
              }}
            />
          </Grid>
          {content}
        </Grid>
      </Container>
    </Draggable>
  );
};

export default ChatBox;
