import { Box, Typography } from '@mui/material';

import { LinkButton } from 'src/components_payroll/Buttons';

const privacyLink = 'https://www.juggl.co/privacy-policy';

const termsLink = 'https://www.juggl.co/terms-of-service';

const PrivacyLabel = () => (
  <Box
    display="inline-block"
    sx={{
      '& a': {
        padding: 0,
      },
    }}
  >
    <LinkButton title="Privacy Policy" variant="CAPTION" routeTo={privacyLink} openInNewTab />
  </Box>
);

const TermsLabel = () => (
  <Box
    display="inline-block"
    sx={{
      '& a': {
        padding: 0,
      },
    }}
  >
    <LinkButton title="Terms of Services" variant="CAPTION" routeTo={termsLink} openInNewTab />
  </Box>
);

const PrivacyTermsText = () => (
  <Box textAlign="center">
    <Typography variant="BODY2" color="light.neutral_grey.70p" display="inline-block" maxWidth="15.6rem">
      By proceeding to the next step, you agree to our <PrivacyLabel /> and <TermsLabel />
    </Typography>
  </Box>
);

export default PrivacyTermsText;
