import { all, takeLatest } from 'redux-saga/effects';

import * as authActions from '../actions';

import getUser from './worker.getUser';

function* authSaga() {
  yield all([takeLatest([authActions.getUser.type], getUser)]);
}

export default authSaga;
