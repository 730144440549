import { all } from 'redux-saga/effects';

import access from './access/saga';
import auth from './auth/saga';
import clients from './clients/saga';
import company from './company/saga';
import constants from './constants/saga';
import docs from './docs/saga';

export default function* rootSaga() {
  yield all([auth(), docs(), clients(), access(), company(), constants()]);
}
