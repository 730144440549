import { all, takeLatest } from 'redux-saga/effects';

import * as authActions from '../../auth/actions';
import * as accessActions from '../actions';

import getAccessItems from './worker.getAccessItems';
import ownerWathcer from './worker.ownerWathcer';
import changeUserAccess from './worker.changeUserAccess';

function* clientsSaga() {
  yield all([takeLatest([accessActions.getAccessItems.type], getAccessItems)]);
  yield all([takeLatest([authActions.addUser.type], ownerWathcer)]);
  yield all([takeLatest([authActions.addCompany.type], ownerWathcer)]);
  yield all([takeLatest([accessActions.changeUserAccess.type], changeUserAccess)]);
}

export default clientsSaga;
