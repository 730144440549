import { Stack, Typography } from '@mui/material';

import { useLocation } from 'react-router-dom';

import HelpBlock from 'src/onboard/components/HelpBlock';

export const CompanySwitcherTooltip = () => (
  <Stack gap="1rem">
    <HelpBlock title="Choose Juggl">
      We handle all financial aspects: payments, calculations, and transfers. You just need to create contracts.
    </HelpBlock>
    <HelpBlock title="Choose Your Company">
      Your team manages the entire payroll process. And we synchronize data and provide tools for calculations.
    </HelpBlock>
  </Stack>
);

export const CardsTooltip = () => {
  const location = useLocation();

  return location.pathname.includes('juggl') ? (
    <Stack gap="1rem">
      <Typography variant="H2">If You Choose Juggl</Typography>
      <Typography variant="SUBTITLE1">
        We handle all financial aspects: payments, calculations, and transfers. You just need to create contracts.
      </Typography>

      <Stack gap="0.25rem">
        <HelpBlock title="Hire Employees">There are restrictions on hiring employees in certain countries.</HelpBlock>
        <HelpBlock title="Hire Contractors">Hire contractors without geographical limitations.</HelpBlock>
      </Stack>
    </Stack>
  ) : (
    <Stack gap="1rem">
      <HelpBlock title="Add Members with Contracts">Manage member documents and requests.</HelpBlock>
      <HelpBlock title="Add Admin Roles Without Contracts">Keep basic member data only, no docs. No request access.</HelpBlock>
    </Stack>
  );
};
