export const LINEAR_SIZES = {
  fullWidth: 630,
  header: 80,
  body: 550,
  input: 70,
  list: 480,
};

export const VACANCY_STATUS_TO_LABEL = {
  review: {
    value: 'Review',
    color: '#ED6C02',
  },
  needDetails: {
    value: 'Need details',
    color: '#D32F2F',
  },
  waitAssignee: {
    value: 'Wait assignee',
    color: '#ED6C02',
  },
  published: {
    value: 'Published',
    color: '#2E7D32',
  },
  onHold: {
    value: 'On hold',
    color: '#0288D1',
  },
};
