import { Button, IconButton as IconButtonMUI, styled } from '@mui/material';

const IconButtonContainer = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '0.75rem',
  borderRadius: '2.25rem',
  backgroundColor: theme.palette.light.primary.contrast,
}));

export const IconButtonWithContainer = ({ Icon, handleClick, ...props }) => (
  <IconButtonContainer onClick={handleClick} {...props}>
    {Icon}
  </IconButtonContainer>
);

export const IconButton = ({ Icon, handleClick, ...props }) => (
  <IconButtonMUI onClick={handleClick} {...props} sx={{ width: '100%', height: '100%' }}>
    {Icon}
  </IconButtonMUI>
);
