import { call, select, put } from 'redux-saga/effects';

import * as docsApi from '../api';
import * as docsActions from '../actions';

function* getDocs() {
  yield put(docsActions.setLoading(true));
  try {
    const { page, pageSize } = yield select(store => store.docs);
    const { company } = yield select(store => store.auth);
    const response = yield call(docsApi.getDocuments, {
      page,
      size: pageSize,
      companyId: company.id,
    });

    yield put(docsActions.setDocs(response.data.docs));
    yield put(docsActions.setTotalDocs(response.data.count));
  } catch (error) {
    console.error(error);
  }
  yield put(docsActions.setLoading(false));
}

export default getDocs;
