import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as chatSelectors from 'src/store/chat/selectors';
import * as chatActions from 'src/store/chat/actions';
import * as authSelectors from 'src/store/auth/selectors';
import useAction from 'src/utils/useAction';

export default function useChat() {
  const socket = useSelector(chatSelectors.getSocket);
  const setShow = useAction(chatActions.setShow);
  const setChatId = useAction(chatActions.setChatId);
  const setMinimize = useAction(chatActions.setMinimize);
  const setChats = useAction(chatActions.setChats);
  const updateChats = useAction(chatActions.updateChats);
  const user = useSelector(authSelectors.getUser);
  const setSendMessageFunc = useAction(chatActions.setSendMessageFunc);

  useEffect(() => {
    if (!user) {
      setShow(false);
      setMinimize(false);
      setChatId(null);
    }
  }, [user, setShow, setMinimize, setChatId]);

  useEffect(() => {
    if (socket) {
      socket.on('chat_list:update', chats => {
        setChats(chats);
      });

      socket.on('chat:update', chat => {
        updateChats(chat);
      });

      setSendMessageFunc(sendMessage);

      socket.on('disconnect', () => {
        console.info('socket disconnected');
      });

      socket.on('connect', () => {
        socket.emit('chat:get');
      });
    }
  }, [socket]);

  const sendMessage = (chatId, message) => {
    socket.emit('message:add', { chatId, message });
  };
}
