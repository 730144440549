import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';

export const Container = styled(Grid)`
  background: #9777f2;
  border-radius: 15px 15px 0 0;
  justify-content: center;
  align-items: center;
  padding: 20px 8px 16px 8px;
  height: 80px;
`;

export const StyledBox = styled(Box)`
  font-family: Roboto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 0 0 0px;
  font-weight: 400;
  font-size: ${({ font }) => font || '16px'};
  letter-spacing: 0.15px;
  color: ${({ color }) => color || '#ffffff'};
`;
