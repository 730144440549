import { all, takeLatest } from 'redux-saga/effects';

import * as docsActions from '../actions';

import uploadFile from './worker.uploadFile';
import deleteFile from './worker.deleteFile';
import createDoc from './wroker.createDoc';
import getDocs from './worker.getDocs';
import deleteDoc from './worker.deleteDoc';
import updateDoc from './worker.updateDoc';
import getSelectDocs from './worker.getSelectDocs';
import getDocsByIds from './worker.getDocsByIds';
import getSignedDocsForVacancy from './worker.getSignedDocsForVacancy';
import updateCompanyDocs from './worker.updateCompanyDocs';
import getCompanyDocIds from './worker.getCompanyDocIds';

function* docsSaga() {
  yield all([
    takeLatest([docsActions.uploadFile.type], uploadFile),
    takeLatest([docsActions.deleteFile.type], deleteFile),
    takeLatest([docsActions.createDoc.type], createDoc),
    takeLatest([docsActions.getDocs.type], getDocs),
    takeLatest([docsActions.deleteDoc.type], deleteDoc),
    takeLatest([docsActions.updateDocument.type], updateDoc),
    takeLatest([docsActions.getSelectDocs.type], getSelectDocs),
    takeLatest([docsActions.getDocsByIds.type], getDocsByIds),
    takeLatest([docsActions.getSignedDocsForVacancy.type], getSignedDocsForVacancy),
    takeLatest([docsActions.updateCompanyDocs.type], updateCompanyDocs),
    takeLatest([docsActions.getCompanyDocIds.type], getCompanyDocIds),
  ]);
}

export default docsSaga;
