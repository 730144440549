import { call, select, put } from 'redux-saga/effects';

import { actions } from 'src/contexts/alert/context';

import * as docsApi from '../api';
import * as docsActions from '../actions';

function* updateDoc() {
  try {
    const { activeFile, activeLabel, activeDescription, activeRequired, activeId } = yield select(store => store.docs);
    const response = yield call(docsApi.updateDoc, {
      data: {
        label: activeLabel,
        description: activeDescription,
        required: activeRequired,
        file: encodeURIComponent(activeFile),
      },
      id: activeId,
    });

    yield put(docsActions.updateDoc(response.data));
    actions.showAlert({
      type: 'success',
      message: 'Document has been updated',
    });
  } catch (error) {
    console.error(error);
  }
}

export default updateDoc;
