import PropTypes from 'prop-types';

import { Icon } from '@iconify/react';

import { Box } from '@mui/material';

const iconContainer = iconName => {
  const Wrapper = ({ color, width, height }) => <Box component={Icon} icon={iconName} width={width} height={height} color={color} />;

  Wrapper.propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  };

  Wrapper.defaultProps = {
    color: 'light.primary.contrast',
    width: '1rem',
    height: '1rem',
  };

  return Wrapper;
};

export default iconContainer;
