/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  proposals: [],
  vacancy: null,
  board: [[], [], [], [], [], [], [], []],
};

const kanbanSlice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    setKanbanProposals: (state, action) => {
      state.proposals = action.payload.filter(el =>
        [el.stage !== 'new', el.stage !== 'pending', el.stage !== 'replied'].every(Boolean),
      );
    },
    updateKanbanProposals: (state, action) => {
      state.proposals = action.payload;
    },
    setKanbanVacancy: (state, action) => {
      state.vacancy = action.payload;
    },
    setKanbanBoard: (state, action) => {
      state.board = action.payload;
    },
    addItemToKanbanBoard: (state, action) => {
      const { columnIndex, proposal } = action.payload;

      state.board[columnIndex] = [...state.board[columnIndex], proposal];
    },
  },
});

export default kanbanSlice.reducer;
