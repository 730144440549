/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  members: [],
  activeMember: null,
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setMembers: (state, { payload }) => {
      state.members = payload;
    },
    setActiveMember: (state, { payload }) => {
      state.activeMember = payload;
    },
    updateMembers: (state, { payload }) => {
      const newMembers = [...state.members].map(member => {
        if (member.id === payload.id) {
          return payload;
        }

        return member;
      });

      state.members = newMembers;

      if (payload.id === state.activeMember?.id) state.activeMember = payload;
    },
    deleteMembers: (state, { payload }) => {
      state.members = [...state.members].filter(member => member.id !== payload);
      if (payload === state.activeMember?.id) state.activeMember = null;
    },
    updateMemberEntity: (state, { payload }) => {
      const newMembers = [...state.members];

      state.members = newMembers;
      if (payload.id === state.activeMember?.id) state.activeMember = payload;
    },
    partialUpdateMember: (state, { payload }) => {
      const { id, data } = payload;

      const newMembers = [...state.members].map(member => {
        if (member.id === id) {
          return {
            ...member,
            ...data,
          };
        }

        return member;
      });

      state.members = newMembers;
      if (id === state.activeMember?.id) {
        state.activeMember = {
          ...state.activeMember,
          ...data,
        };
      }
    },
  },
});

export default companySlice.reducer;
