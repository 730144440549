import { useContext } from 'react';

import { AlertsContext } from 'src/contexts/alert/context';

import { StyledAlert, StyledStack } from './Alert.style';

const Alerts = () => {
  const { alerts } = useContext(AlertsContext);

  const renderContent = arr =>
    arr.map((item, i) => (
      <StyledAlert key={i} variant="filled" severity={item.type}>
        {item.message}
      </StyledAlert>
    ));

  if (alerts.length) {
    return (
      <StyledStack sx={{ zIndex: 2000 }} spacing={2}>
        {renderContent(alerts)}
      </StyledStack>
    );
  }

  return null;
};

export default Alerts;
