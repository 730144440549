import PropTypes from 'prop-types';
import { Box, Link } from '@mui/material';

const TextLink = ({ title, navigateTo }) => (
  <Box
    display="inline-block"
    sx={{
      '& a': {
        padding: 0,
      },
    }}
  >
    <Link variant="SUBTITLE1" color="light.primary.dark" href={navigateTo} underline="none">
      {title}
    </Link>
  </Box>
);

TextLink.propTypes = {
  title: PropTypes.string.isRequired,
  navigateTo: PropTypes.string.isRequired,
};

export default TextLink;
