import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { Stack } from '@mui/material';

import HelpBlock from 'src/onboard/components/HelpBlock';
import { TextLink } from 'src/components_payroll/Texts';

import * as authSelectors from 'src/store/auth/selectors';

const settingsRoleLink = '/member-admin/settings/role';
const settingsProjectLink = '/member-admin/settings/projects';

export const PositionDetailsTooltip = () => {
  const user = useSelector(authSelectors.getUser);

  const isUserAdmin = useMemo(() => user?.role === 'hunter-admin', [user]);

  return (
    <Stack gap="1rem">
      <HelpBlock title="Add Role">
        {isUserAdmin ? (
          <>
            Can&apos;t find the role you need? <TextLink title="Click here" navigateTo={settingsRoleLink} />.
          </>
        ) : (
          <>If you didn&apos;t find a necessary role, please contact admin.</>
        )}
      </HelpBlock>
      {isUserAdmin && (
        <HelpBlock title="Add Project">
          If a project is missing, you can add it <TextLink title="(link)" navigateTo={settingsProjectLink} />.
        </HelpBlock>
      )}
    </Stack>
  );
};
