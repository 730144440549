import styled from 'styled-components';
import { styled as stylesMUI } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { MdDone, MdDoneAll } from 'react-icons/md';

export const StyledStack = stylesMUI(Stack)`
  flex-direction: row;
  align-items: center;
`;

export const Title = stylesMUI(Box)`
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.17px;
  color: #2065d1;
`;

export const Container = stylesMUI(Box)`
  padding: 5px 10px 5px 16px;
  border-radius: 8px;
  float: ${({ title }) => (title ? 'left' : 'right')};
  background: ${({ title }) => (title ? 'rgba(0, 0, 0, 0.04)' : '#9777F2')};
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.17px;
  color: ${({ title }) => (title ? 'rgba(0, 0, 0, 0.87)' : '#FFFFFF')};
`;

export const Date = stylesMUI(Box)`
  padding: '4px 0 0 8px';
  float: 'right';
  font-family: 'Roboto';
  font-style: 'italic';
  font-weight: 400;
  font-size: '12px';
  line-height: '20px';
  letter-spacing: '0.4px';
  color: ${({ title }) => (title ? 'rgba(0, 0, 0, 0.38)' : '#FFFFFF')};
`;

export const Wrapper = stylesMUI(Box)`
  padding: ${({ title }) => (title ? '0 64px 0 0' : '0 0 0 96px')};
  margin-top: 8px;
`;

export const SentIcon = stylesMUI(MdDone)`
  color: ${({ iswhite }) => (iswhite ? '#ffffff' : '#9777F2')};
  margin-left: 4px;
`;

export const ReadIcon = stylesMUI(MdDoneAll)`
  color: ${({ iswhite }) => (iswhite ? '#ffffff' : '#9777F2')};
  margin-left: 4px;
`;

export const ContentWrapper = styled.span`
  display: flex;
  justify-content: ${({ isMe }) => (isMe ? 'flex-end' : 'flex-start')};
  align-items: center;
`;

export const Image = styled.img`
  width: 120px;
  height: 120px;
`;
