import { call, put } from 'redux-saga/effects';

import * as accessActions from '../actions';
import * as accessApi from '../api';

function* getClients() {
  yield put(accessActions.setLoading(true));
  try {
    const response = yield call(accessApi.getAccessItems, { type: 'ui' });

    yield put(accessActions.setAccessItems(response.data));
  } catch (error) {
    console.error(error);
  }
  yield put(accessActions.setLoading(false));
}

export default getClients;
