/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
  minimize: false,
  socket: null,
  chatId: null,
  chats: {},
  sendMessage: () => null,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setShow: (state, action) => {
      state.show = action.payload;
      if (action.payload === false) {
        state.minimize = false;
      }
    },
    setMinimize: (state, action) => {
      state.minimize = action.payload;
    },
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
    setChatId: (state, action) => {
      state.chatId = action.payload;
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    updateChats: (state, action) => {
      const newChat = action.payload;

      state.chats = {
        ...state.chats,
        [newChat.id]: newChat,
      };
    },
    setSendMessageFunc: (state, action) => {
      state.sendMessage = action.payload;
    },
  },
});

export default chatSlice.reducer;
