/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  clients: [],
  page: 0,
  pageSize: 5,
  total: 0,
  activeClient: null,
  search: '',
  agencyCompanyName: '',
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setClients: (state, { payload }) => {
      state.clients = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setAgencyCompanyName: (state, { payload }) => {
      state.agencyCompanyName = payload;
    },
    setActiveClient: (state, { payload }) => {
      state.activeClient = payload;
    },
    updateClient: (state, { payload }) => {
      const newClients = [...state.clients].map(client => {
        if (client.id === payload.id) {
          return {
            ...client,
            ...payload,
          };
        }

        return client;
      });

      state.clients = newClients;

      if (state.activeClient?.id === payload.id) {
        state.activeClient = {
          ...state.activeClient,
          ...payload,
        };
      }
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
  },
});

export default clientsSlice.reducer;
