import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import 'simplebar/src/simplebar.css';
import 'react-chat-elements/dist/main.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import ErrorHandlerWrapper from './components/ErrorHandlerWrapper';
import { AlertsContextProvider } from './contexts/alert/context';
import store from './store';

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <ErrorHandlerWrapper>
        <AlertsContextProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </AlertsContextProvider>
      </ErrorHandlerWrapper>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

reportWebVitals();
