/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeFile: '',
  activeLabel: '',
  activeDescription: '',
  activeRequired: false,
  activeId: null,
  docs: [],
  page: 0,
  pageSize: 10,
  totalDocs: 0,
  loading: false,
  selectDocs: [],
  selectDocsLoading: false,
  uploadDocs: [],
  uploadDocsLoading: false,
  signedDocs: [],
  signedDocsLoading: false,
  companyDocIds: [],
};

export const docsSlice = createSlice({
  name: 'docs',
  initialState,
  reducers: {
    setActiveFile: (state, { payload }) => {
      state.activeFile = payload;
    },
    setActiveLabel: (state, { payload }) => {
      state.activeLabel = payload;
    },
    setActiveDescription: (state, { payload }) => {
      state.activeDescription = payload;
    },
    setActiveRequired: (state, { payload }) => {
      state.activeRequired = payload;
    },
    setActiveId: (state, { payload }) => {
      state.activeId = payload;
    },
    setActiveDoc: (state, { payload }) => {
      const { id, label, file, required, description } = payload;

      state.activeId = id;
      state.activeLabel = label;
      state.activeFile = file;
      state.activeRequired = required;
      state.activeDescription = description;
    },
    resetActiveData: state => {
      state.activeFile = '';
      state.activeLabel = '';
      state.activeDescription = '';
      state.activeRequired = false;
      state.activeId = null;
    },
    setDocs: (state, { payload }) => {
      state.docs = payload;
    },
    addNewDoc: (state, { payload }) => {
      if (state.docs.length < state.pageSize) {
        const newDocs = [...state.docs];

        newDocs.push(payload);

        state.docs = newDocs;
      }
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    setTotalDocs: (state, { payload }) => {
      state.totalDocs = payload;
    },
    removeDoc: (state, { payload }) => {
      state.docs = state.docs.filter(doc => doc.id !== payload);
    },
    updateDoc: (state, { payload }) => {
      state.docs = state.docs.map(doc => {
        if (doc.id === payload.id) {
          return payload;
        }

        return doc;
      });
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setSelectDocs: (state, { payload }) => {
      state.selectDocs = payload;
    },
    setSelectDocsLoading: (state, { payload }) => {
      state.selectDocsLoading = payload;
    },
    setUploadDocs: (state, { payload }) => {
      state.uploadDocs = payload;
    },
    setUploadDocsLoading: (state, { payload }) => {
      state.uploadDocsLoading = payload;
    },
    setSignedDocs: (state, { payload }) => {
      state.signedDocs = payload;
    },
    setSignedDocsLoading: (state, { payload }) => {
      state.signedDocsLoading = payload;
    },
    addSignedDoc: (state, { payload }) => {
      const newDocs = state.signedDocs.filter(doc => doc.id !== payload.id);

      state.signedDocs = [...newDocs, payload];
    },
    setCompanyDocIds: (state, { payload }) => {
      state.companyDocIds = payload;
    },
  },
});

export default docsSlice.reducer;
