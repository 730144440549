import { call, select, put } from 'redux-saga/effects';

import { actions } from 'src/contexts/alert/context';

import * as docsApi from '../api';
import * as docsActions from '../actions';

function* deleteDoc() {
  try {
    const { activeId } = yield select(store => store.docs);

    yield call(docsApi.deleteDoc, activeId);
    yield put(docsActions.removeDoc(activeId));
    yield put(docsActions.resetActiveData());
    actions.showAlert({
      type: 'success',
      message: 'Document has been deleted',
    });
  } catch (error) {
    console.error(error);
  }
}

export default deleteDoc;
