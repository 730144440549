import { Box, Stack, styled } from '@mui/material';

import { SubmitButton, IconButton } from 'src/components_payroll/Buttons';
import { IconX } from 'src/components_payroll/Icons';

export const Container = styled(Stack)(({ theme }) => ({
  borderRadius: '0.5rem',
  padding: '1rem 1.5rem',
  backgroundColor: theme.palette.light.primary.contrast,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.light.neutral_grey['10p'],
  gap: '2rem',
  maxWidth: '300px',
}));

const Tooltip = ({ continuous, index, step, backProps, closeProps, primaryProps, tooltipProps }) => (
  <Stack position="relative" justifyContent="center">
    {!step.hideCloseButton && (
      <Box position="absolute" top="-5px" right="-7px">
        <IconButton Icon={<IconX color="light.neutral_grey.30p" width="1.5rem" height="1.5rem" />} {...closeProps} />
      </Box>
    )}
    <Container {...tooltipProps}>
      {step.title && <Box>{step.title}</Box>}
      <Box>{step.content}</Box>
      {!step.hideFooter && (
        <Stack direction="row" gap="0.5rem" justifyContent={index && !step.hideBackButton ? 'space-between' : 'flex-end'}>
          {Boolean(index) && !step.hideBackButton && (
            <Box>
              <SubmitButton mode="light" {...backProps} id="back" isEndIcon={false} />
            </Box>
          )}
          {continuous && (
            <Box>
              <SubmitButton {...primaryProps} id="next" isEndIcon={false} />
            </Box>
          )}
          {!continuous && (
            <Box>
              <SubmitButton {...closeProps} id="close" isEndIcon={false} title="End" />
            </Box>
          )}
        </Stack>
      )}
    </Container>
  </Stack>
);

export default Tooltip;
