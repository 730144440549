import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { LoadingButton } from '@mui/lab';
import { Box, Divider, Stack, Typography, styled } from '@mui/material';

import { authService } from 'src/services';
import * as authActions from 'src/store/auth/actions';
import * as authSelectors from 'src/store/auth/selectors';
import useAction from 'src/utils/useAction';

import Iconify from 'src/components/Iconify';
import { actions } from 'src/contexts/alert/context';
import useQuery from 'src/hooks/useQuery';

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  background: '#fff',
  '&.MuiButton-containedPrimary': {
    boxShadow: 'none',
    fontSize: '0.75rem',
    fontWeight: '500',
    borderRadius: '0.25rem',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.light.primary.shades['40p'],
    '&:hover': {
      boxShadow: 'none',
      background: '#896DDE',
    },
    '&:active': {
      boxShadow: 'none',
      background: '#896DDE',
    },
  },
}));

const GoogleAuthButton = ({ type, userId, title, disabled, withSeparator }) => {
  const user = useSelector(authSelectors.getUser);
  const getUser = useAction(authActions.getUser);

  const token = useQuery('token') || '';
  const isConnect = type === 'connect';

  const isError = useQuery('error') === 'true';
  const errorMessage = useQuery('message');

  const [isLoading, setIsLoading] = useState(false);
  const [googleAuthUrl, setGoogleAuthUrl] = useState(null);

  useEffect(() => {
    if (!user) {
      authService.getGoogleAuthLink({ type, userId, token }).then(({ link }) => setGoogleAuthUrl(link));
    }

    if (user && !user?.googleId) {
      authService.getGoogleAuthLink({ type, userId: user.id }).then(({ link }) => setGoogleAuthUrl(link));
    }
  }, [user]);

  useEffect(() => {
    if (isError) {
      actions.showAlert({
        type: 'error',
        message: errorMessage,
      });
    }
  }, [isError]);

  useEffect(() => {
    setIsLoading(false);
  }, [googleAuthUrl]);

  const onClickDisconnect = () => {
    setIsLoading(true);

    authService.googleAuthLogout().then(() => {
      getUser();
      setIsLoading(false);
    });
  };

  let buttonTypeProps = googleAuthUrl || !isConnect ? { href: googleAuthUrl, component: 'a' } : { onClick: onClickDisconnect };
  let buttonTitle = title;

  if (googleAuthUrl) buttonTypeProps = { href: googleAuthUrl, component: 'a' };
  if (isConnect) buttonTitle = googleAuthUrl ? 'Connect a Google' : 'Disconnect a Google ';

  return (
    <Stack gap="1.5rem" width="100%">
      <StyledButton {...buttonTypeProps} variant="outlined" disabled={disabled} loading={isLoading}>
        <Stack
          direction="row"
          gap="0.62rem"
          justifyContent="center"
          alignItems="center"
          textTransform="none"
          borderRadius="0.25rem"
          width="100%"
        >
          <Iconify icon="flat-color-icons:google" sx={{ width: '1.5rem', height: '1.5rem' }} />
          <Typography variant="BUTTON" color="light.text.secondary">
            {buttonTitle}
          </Typography>
        </Stack>
      </StyledButton>

      {withSeparator && (
        <Stack direction="row" width="100%" alignItems="center" gap="0.25rem">
          <Box flex="5">
            <Divider />
          </Box>
          <Typography flex="7" textAlign="center" variant="BODY2" color="light.neutral_grey.30p">
            or enter with password
          </Typography>
          <Box flex="5">
            <Divider />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

GoogleAuthButton.propTypes = {
  type: PropTypes.oneOf(['connect', 'registration', 'login']),
  title: PropTypes.string,
  disabled: PropTypes.bool,
  userId: PropTypes.string,
  withSeparator: PropTypes.bool,
};

GoogleAuthButton.defaultProps = {
  disabled: false,
  title: 'Connect',
  userId: '',
  type: 'login',
  withSeparator: false,
};

export default GoogleAuthButton;
