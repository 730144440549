/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessItems: [],
  loading: false,
  isOwner: false,
};

export const clientsSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
    setAccessItems: (state, { payload }) => {
      state.accessItems = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setIsOwner: (state, { payload }) => {
      state.isOwner = payload;
    },
  },
});

export default clientsSlice.reducer;
