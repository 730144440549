import { Stack } from '@mui/material';

import HelpBlock from 'src/onboard/components/HelpBlock';
import { ClickableSupportEmail } from 'src/components_payroll/Texts';

export const ContractDetailsTooltip = () => (
  <Stack gap="1rem">
    <HelpBlock title="Any Paper Contract?">Specify if you have a paper version of this contract.</HelpBlock>
    <HelpBlock title="Have Digital Copy?">
      If there is a terminated paper copy contract, consider creating a digital version in Juggl.
    </HelpBlock>
    <HelpBlock title="Use e-signature platform?">
      Connect Juggl with platforms like DocuSign or PandaDoc in Agreement template section. So you can automatically send contracts &
      get e-signatures from your team. Need an account? Email us at <ClickableSupportEmail />.
    </HelpBlock>
  </Stack>
);

export const MemberDetailsTooltip = () => (
  <Stack gap="1rem">
    <HelpBlock title="Add Address">Enter an official registered address, not temporary residence address. </HelpBlock>
  </Stack>
);
