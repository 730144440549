import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Stack, Typography } from '@mui/material';

import { IconArrowLeft } from 'src/components_payroll/Icons';

const sxTxt = {
  color: 'light.primary.dark',
  '&.MuiButton-root:hover': {
    color: 'light.primary.shades.60p',
    backgroundColor: 'inherit',
  },
  '& :disabled': {
    color: 'light.primary.shades.30p',
  },
};

const BackButton = ({ isDisabled, handleClick, savePrevState }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const arrowColor = isDisabled ? 'light.primary.shades.30p' : 'light.primary.shades.60p';

  const backToPrevPage = () => {
    const navigateOptions = savePrevState ? { state } : {};

    navigate(-1, navigateOptions);
  };

  const onClick = handleClick || backToPrevPage;

  return (
    <Button onClick={onClick} disabled={isDisabled} sx={{ padding: '0.5rem' }}>
      <Stack direction="row" gap="0.5rem">
        <IconArrowLeft color={arrowColor} />

        <Typography variant="BUTTON" sx={sxTxt}>
          Back
        </Typography>
      </Stack>
    </Button>
  );
};

BackButton.propTypes = {
  isDisabled: PropTypes.bool,
  handleClick: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  savePrevState: PropTypes.bool,
};

BackButton.defaultProps = {
  isDisabled: false,
  handleClick: null,
  savePrevState: false,
};

export default BackButton;
