import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useSelector } from 'react-redux';

import * as modalSelectors from 'src/store/modal/selectors';
import * as modalActions from 'src/store/modal/actions';

import useAction from '../../utils/useAction';

const AlertDialogGlobal = () => {
  const getModalTitle = useSelector(modalSelectors.getModalTitle);
  const getModalBody = useSelector(modalSelectors.getModalBody);
  const getModalIsOpen = useSelector(modalSelectors.getModalIsOpen);

  const closeAlertDialog = useAction(modalActions.closeAlertDialog);

  return (
    <Dialog
      open={getModalIsOpen}
      onClose={() => closeAlertDialog()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{getModalTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{getModalBody}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeAlertDialog()} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialogGlobal;
