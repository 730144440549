import { alpha } from '@mui/material/styles';

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const PRIMARY = {
  lighter: '#D1E9FC',
  light: '#ab92f4',
  main: '#9777F2',
  dark: '#6953a9',
  darker: '#061B64',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#D6E7FF',
  light: '#ab92f4',
  main: '#9777F2',
  dark: '#6953a9',
  darker: '#091A7A',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#6fbf73',
  main: '#4CAF50',
  dark: '#357a38',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#ffac33',
  main: '#FF9800',
  dark: '#b26a00',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#ef5350',
  main: '#d32f2f',
  dark: '#c62828',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

// NOTE: new actove colors
const LIGHT = {
  neutral_grey: {
    '2p': '#FAFAFA',
    '5p': '#F2F3F3',
    '7p': '#EDEEEE',
    '10p': '#E6E6E7',
    '15p': '#D9DADB',
    '20p': '#CCCECF',
    '30p': '#B3B5B7',
    '40p': '#9A9D9F',
    '50p': '#808486',
    '60p': '#676B6E',
    '70p': '#4E5356',
    '80p': '#353A3E',
    '90p': '#1B2226',
    '100p': '#02090E',
  },
  primary: {
    main: '#9E7DFE',
    dark: '#9777F2',
    light: '#AA8CFF',
    contrast: '#FFFFFF',
    shades: {
      '2p': '#FDFCFF',
      '5p': '#FAF9FF',
      '8p': '#F7F5FF',
      '10p': '#F5F2FF',
      '12p': '#F3EFFF',
      '20p': '#ECE5FF',
      '30p': '#E2D8FF',
      '40p': '#D8CBFF',
      '50p': '#CEBEFF',
      '60p': '#C5B1FE',
      '70p': '#BBA4FE',
      '80p': '#B197FE',
      '90p': '#A88AFE',
      '100p': '#9E7DFE',
      '105p': '#9778F3',
      '110p': '#9072E8',
      '115p': '#896DDE',
      '120p': '#8267D3',
    },
  },
  secondary: {
    main: '#99C4FF',
    dark: '#709EDE',
    light: '#C8DFFF',
    shades: {
      '5p': '#FAFCFF',
      '8p': '#F7FAFF',
      '10p': '#F5F9FF',
      '15p': '#F0F6FF',
      '20p': '#EBF3FF',
      '30p': '#E0EDFF',
      '40p': '#D6E7FF',
      '50p': '#CCE1FF',
      '60p': '#C2DCFF',
      '70p': '#B8D6FF',
      '80p': '#ADD0FF',
      '90p': '#A3CAFF',
    },
  },
  text: {
    primary: '#1B2226',
    secondary: '#4E5356',
    tertiary: '#808486',
    disabled: '#B3B5B7',
    white: '#ffffff',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    selected: 'rgba(0, 0, 0, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabled_background: 'rgba(0, 0, 0, 0.12)',
    focus: 'rgba(0, 0, 0, 0.12)',
    shades: {
      '30p': 'rgba(0, 0, 0, 0.3)',
    },
  },
  error: {
    main: '#D32F2F',
    dark: '#C62828',
    light: '#EF5350',
    shades: {
      '4p': '#FDF7F7',
      '8p': '#FBEEEE',
      '10p': '#FBEAEA',
      '12p': '#FAE6E6',
      '20p': '#F6D5D5',
      '30p': '#F2C1C1',
      '40p': '#EDACAC',
      '50p': '#E99797',
      '60p': '#E58282',
      '70p': '#E06D6D',
      '80p': '#DC5959',
      '90p': '#D74444',
      '100p': '#D32F2F',
      '105p': '#C92D2D',
      '110p': '#C02B2B',
      '115p': '#B72929',
      '120p': '#AD2727',
    },
  },
  warning: {
    main: '#ED6C02',
    dark: '#E65100',
    light: '#FF9800',
    shades: {
      '4p': '#FEF9F5',
      '8p': '#FEF3EB',
      '10p': '#FDF0E6',
      '12p': '#FDEDE1',
      '20p': '#FBE2CC',
      '30p': '#FAD3B3',
      '40p': '#F8C49A',
      '50p': '#F6B580',
      '60p': '#F4A767',
      '70p': '#F2984E',
      '80p': '#F18935',
      '90p': '#EF7B1B',
      '100p': '#ED6C02',
      '105p': '#E26702',
      '110p': '#D86302',
      '115p': '#CD5E02',
      '120p': '#C35902',
    },
  },
  info: {
    main: '#0288D1',
    dark: '#01579B',
    light: '#03A9F4',
    shades: {
      '4p': '#F5FAFD',
      '12p': '#E1F1F9',
      '20p': '#CCE7F6',
      '30p': '#B3DBF1',
      '40p': '#9ACFED',
      '50p': '#80C3E8',
      '60p': '#67B8E3',
      '70p': '#4EACDF',
      '80p': '#35A0DA',
      '90p': '#1B94D6',
      '160p': 'rgba(2, 136, 209, 0.6)',
      '190p': 'rgba(2, 136, 209, 0.9)',
    },
  },
  success: {
    main: '#2E7D32',
    dark: '#1B5E20',
    light: '#4CAF50',
    shades: {
      '4p': '#F7FAF7',
      '10p': '#EAF2EB',
      '12p': '#E6EFE6',
      '20p': '#D5E5D6',
      '30p': '#C0D8C2',
      '40p': '#ABCBAD',
      '50p': '#96BE98',
      '60p': '#82B184',
      '70p': '#6DA470',
      '80p': '#58975B',
      '90p': '#438A47',
      '100p': '#2E7D32',
      '105p': '#2C7830',
      '110p': '#2A722E',
      '115p': '#286D2C',
      '120p': '#266729',
    },
  },
  background: {
    default: '#F2F4F8',
    table_header: '#FAFBFC',
    paper: '#FFFFFF',
  },
  other: {
    divider: 'rgba(0, 0, 0, 0.12)',
    outlined_border: 'rgba(0, 0, 0, 0.23)',
    backdrop_overlay: 'rgba(0, 0, 0, 0.5)',
    filled_input_background: 'rgba(0, 0, 0, 0.06)',
    standart_input_line: 'rgba(0, 0, 0, 0.42)',
    snackbar: '#323232',
    ration_active: '#FFB400',
  },
  blue: {
    25: '#F0FAFE',
    50: '#E1F5FE',
    70: '#CAEDFD',
    200: '#81D4FA',
  },
};

const DARK = {
  text: {
    primary: 'rgba(255, 255, 255, 1)',
    secondary: 'rgba(255, 255, 255, 0.7)',
    disabled: 'rgba(255, 255, 255, 0.5)',
    shades: {
      '4p': 'rgba(255, 255, 255, 0.04)',
      '12p': 'rgba(255, 255, 255, 0.18)',
      '30p': 'rgba(255, 255, 255, 0.3)',
    },
  },
  primary: {
    main: '#90CAF9',
    dark: '#42A5F5',
    light: '#E3F2FD',
    contrast: 'rgba(255, 255, 255, 0.87)',
    shades: {
      '8p': '#F6FBFF',
      '12p': '#F2F9FE',
      '16p': '#EDF7FE',
      '30p': '#DEEFFD',
      '50p': '#C7E4FC',
    },
  },
  secondary: {
    main: '#CE93D8',
    dark: '#AB47BC',
    light: '#F3E5F5',
    contrast: 'rgba(255, 255, 255, 0.87)',
    shades: {
      '8p': '#FBF6FC',
      '10p': '#FAF4FB',
      '12p': '#F9F2FA',
      '16p': '#F7EEF9',
      '20p': '#F5E9F7',
      '30p': '#F0DFF3',
      '40p': '#EBD4EF',
      '50p': '#E6C9EB',
      '60p': '#E2BEE8',
      '70p': '#DDB3E4',
      '80p': '#D8A9E0',
      '90p': '#D39EDC',
    },
  },
  action: {
    active: 'rgba(255, 255, 255, 0.56)',
    hover: 'rgba(255, 255, 255, 0.08)',
    selected: 'rgba(255, 255, 255, 0.16)',
    disabled: 'rgba(255, 255, 255, 0.30)',
    disabled_background: 'rgba(255, 255, 255, 0.12)',
    focus: 'rgba(255, 255, 255, 0.12)',
    shades: {
      '30p': 'rgba(255, 255, 255, 0.30)',
    },
  },
  error: {
    main: '#F44336',
    dark: '#D32F2F',
    light: '#E57373',
    contrast: '#FFFFFF',
    shades: {
      '4p': '#FFF7F7',
      '8p': '#FEF0EF',
      '10p': '#FEECEB',
      '12p': '#FEE8E7',
      '20p': '#FDD9D7',
      '30p': '#FCC7C3',
      '40p': '#FBB4AF',
      '50p': '#F9A19A',
      '60p': '#F88E86',
      '70p': '#F77B72',
      '80p': '#F6695E',
      '90p': '#F5564A',
      '100p': '#F44336',
      '105p': '#E94034',
      '110p': '#DE3D31',
      '115p': '#D33A2F',
      '120p': '#C8372D',
    },
  },
  warning: {
    main: '#FFA726',
    dark: '#F57C00',
    light: '#FFB74D',
    shades: {
      '8p': '#FFF8EE',
      '10p': '#FFF6E9',
      '12p': '#FFF4E5',
      '20p': '#FFEDD4',
      '30p': '#FFE5BE',
      '40p': '#FFDCA8',
      '50p': '#FFD392',
      '60p': '#FFCA7D',
      '70p': '#FFC167',
      '80p': '#FFB951',
      '90p': '#FFB03C',
      '100p': '#FFA726',
      '105p': '#F4A024',
      '110p': '#E89823',
      '115p': '#DD9121',
      '120p': '#D18A20',
    },
  },
  info: {
    main: '#29B6F6',
    dark: '#0288D1',
    light: '#4FC3F7',
    shades: {
      '8p': 'rgba(2, 136, 209, 0.08)',
      '12p': 'rgba(2, 136, 209, 0.12)',
      '30p': 'rgba(2, 136, 209, 0.3)',
      '50p': 'rgba(2, 136, 209, 0.5)',
      '160p': 'rgba(2, 136, 209, 0.6)',
      '190p': 'rgba(2, 136, 209, 0.9)',
    },
  },
  success: {
    main: '#66BB6A',
    dark: '#388E3C',
    light: '#81C784',
    shades: {
      '4p': '#F9FCF9',
      '8p': '#F3FAF3',
      '10p': '#F0F8F0',
      '12p': '#EDF7ED',
      '20p': '#E0F1E1',
      '30p': '#D1EBD2',
      '40p': '#C2E4C3',
      '50p': '#B2DDB4',
      '60p': '#A3D6A6',
      '70p': '#94CF97',
      '80p': '#85C988',
      '90p': '#75C279',
      '100p': '#66BB6A',
      '105p': '#61B365',
      '110p': '#5DAB61',
      '115p': '#58A35C',
      '120p': '#549B58',
    },
  },
  background: {
    default: '#121212',
  },
  other: {
    divider: 'rgba(255, 255, 255, 0.12)',
    outlined_border: 'rgba(255, 255, 255, 0.23)',
    filled_input_background: 'rgba(255, 255, 255, 0.09)',
    standart_input_line: 'rgba(255, 255, 255, 0.42)',
    snackbar: '#323232',
    rating_active: '#FFB400',
  },
};

const GREYISH_BLUE = {
  50: '#FCFCFD',
  70: '#FBFBFD',
  100: '#F9FAFC',
  150: '#F6F7FA',
  200: '#F2F4F8',
  300: '#E6EAF1',
  400: '#DFE4EE',
  500: '#D9DFEB',
  600: '#D3DAE7',
  700: '#CDD5E4',
  800: '#C6CFE0',
  900: '#C0CADD',
  1000: '#B6C1D7',
  1050: '#AEB9CE',
  1100: '#A6B0C5',
  1150: '#9EA8BC',
  1200: '#959FB2',
  1250: '#8D97A9',
  1300: '#858FA0',
  1350: '#7D8697',
  1400: '#757E8E',
  1450: '#6D7685',
  1500: '#656D7C',
  1550: '#5C6572',
  1600: '#545D69',
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  icWhite: {
    main: '#fff',
    darker: '#fff',
  },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: '#f2f4f8', neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  // NOTE: new active colors
  light: LIGHT,
  dark: DARK,
  greyish_blue: GREYISH_BLUE,
};

export default palette;
