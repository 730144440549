import { ContractDetailsTooltip, MemberDetailsTooltip } from 'src/pages/Member-HR/Offer/OnBoard/Step1';

export default [
  {
    target: '[tour="new-offer-step-1-contract-details"]',
    content: <ContractDetailsTooltip />,
    placement: 'left',
  },
  {
    target: '[tour="new-offer-step-1-contacts"]',
    content: <MemberDetailsTooltip />,
    placement: 'right-end',
  },
];
