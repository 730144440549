import { call, put } from 'redux-saga/effects';

import * as docsApi from '../api';
import * as docsActions from '../actions';

function* getSignedDocsForVacancy({ payload }) {
  try {
    const response = yield call(docsApi.getSignedDocsForVacancy, payload);

    yield put(docsActions.setSignedDocs(response.data));
  } catch (error) {
    console.error(error);
  }
}

export default getSignedDocsForVacancy;
