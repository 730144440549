import { call, select, put } from 'redux-saga/effects';

import { actions } from 'src/contexts/alert/context';

import * as docsApi from '../api';
import * as docsActions from '../actions';

function* createDoc() {
  try {
    const { activeFile, activeLabel, activeDescription, activeRequired } = yield select(store => store.docs);
    const response = yield call(docsApi.createDocument, {
      label: activeLabel,
      description: activeDescription,
      required: activeRequired,
      file: encodeURIComponent(activeFile),
    });

    yield put(docsActions.addNewDoc(response.data));
    actions.showAlert({
      type: 'success',
      message: 'Document has been created',
    });
  } catch (error) {
    console.error(error);
  }
}

export default createDoc;
