import { call, put } from 'redux-saga/effects';

import * as docsApi from '../api';
import * as docsActions from '../actions';

function* uploadFile({ payload: { signData, ...rest } }) {
  try {
    const response = yield call(docsApi.uploadFile, { ...rest });
    const signedDocResponse = yield call(docsApi.createSignedDocument, { ...signData, file: response.data.fileName });

    yield put(docsActions.addSignedDoc(signedDocResponse.data));
  } catch (error) {
    console.error(error);
  }
}

export default uploadFile;
