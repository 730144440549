import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';

import { IconInfoCircle } from '../Icons';

const InfoBlock = ({ children, Icon, textColor }) => (
  <Stack direction="row" gap="0.5rem">
    <Stack width="1rem" alignItems="flex-start">
      <Icon color="light.primary.shades.60p" />
    </Stack>
    <Typography variant="CAPTION" color={textColor}>
      {children}
    </Typography>
  </Stack>
);

InfoBlock.propTypes = {
  children: PropTypes.string.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.oneOf([null])]),
  textColor: PropTypes.string,
};

InfoBlock.defaultProps = {
  Icon: IconInfoCircle,
  textColor: 'light.neutral_grey.30p',
};

export default InfoBlock;
