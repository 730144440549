import axios from 'axios';

import apiErrorHandler from 'src/functions/apiErrorHandler';

axios.defaults.withCredentials = true;

const responseErrorHandler = error => {
  apiErrorHandler(error);

  const status = error.response?.status;

  window.postMessage({ status });

  return Promise.reject(error);
};

axios.interceptors.response.use(response => response, responseErrorHandler);

const API_URL = `${process.env.REACT_APP_API_URL}/v1/`;

export const apiInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

apiInstance.interceptors.response.use(response => response, responseErrorHandler);
