import { apiInstance } from 'src/functions/request';

export const uploadFile = ({ formData, progressHandler = () => null, requestController }) =>
  apiInstance.post('uploads/doc/sign', formData, {
    onUploadProgress: progressHandler,
    signal: requestController.signal,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const createDocument = data => apiInstance.post('doc', data);

export const getDocuments = ({ page, size, companyId }) => apiInstance.get(`doc/${companyId}?page=${page}&size=${size}`);

export const deleteDoc = id => apiInstance.delete(`doc/${id}`);

export const updateDoc = ({ id, data }) => apiInstance.put(`doc/${id}`, data);

export const getDocumentsByIds = ids => apiInstance.post('doc/ids', { ids });

export const createSignedDocument = data => apiInstance.post('doc/sign', data);

export const getSignedDocsForVacancy = data => apiInstance.post('doc/sign/docs', data);

export const updateCompanyDocs = ({ companyId, ...data }) => apiInstance.patch(`company/${companyId}`, data);

export const getCompanyDocIds = companyId => apiInstance.get(`company/doc-ids/${companyId}`);
