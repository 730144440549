import React, { useMemo, useCallback } from 'react';
import { Box, Avatar } from '@mui/material';

import { Title, Container, Date, Wrapper, SentIcon, ReadIcon, ContentWrapper, StyledStack, Image } from './Message.style';

const Message = ({ type, title, text, dateString, status, data }) => {
  const nameBox = useMemo(() => {
    if (title) return <Title>{title}</Title>;

    return null;
  }, [title]);

  const avatarBox = useMemo(() => {
    if (title) {
      return (
        <Box
          component="span"
          sx={{
            pr: '8px',
          }}
        >
          <Avatar />
        </Box>
      );
    }

    return null;
  }, [title]);

  const messageBox = useCallback(
    iswhite => (
      <Container component="span" title={title}>
        {nameBox ? <ContentWrapper isMe={iswhite}>{nameBox}</ContentWrapper> : null}
        <ContentWrapper isMe={iswhite}>{type === 'text' ? text : <Image src={data?.uri} />}</ContentWrapper>
        <ContentWrapper isMe={iswhite}>
          <Date component="span" title={title}>
            {dateString}
          </Date>
          {status === 'read' ? <ReadIcon iswhite={iswhite} /> : <SentIcon iswhite={iswhite} />}
        </ContentWrapper>
      </Container>
    ),
    [data, dateString, nameBox, text, title, type, status],
  );

  return (
    <Wrapper title={title}>
      {title ? (
        <StyledStack>
          {avatarBox}
          {messageBox(false)}
        </StyledStack>
      ) : (
        messageBox(true)
      )}
    </Wrapper>
  );
};

export default Message;
