import { actions } from 'src/contexts/alert/context';

const preventAlertPaths = [
  {
    path: 'auth/get-user',
    method: 'get',
  },
];

const isAlertPrevented = config => !!preventAlertPaths.find(item => item.path === config.url && item.method === config.method);

const apiErrorHandler = error => {
  if (!error?.config) return;
  if (!error?.response) return;

  if (error.response?.data?.message && !isAlertPrevented(error.config)) {
    actions.showAlert({
      type: 'error',
      message: error.response.data.message,
    });
  }
};

export default apiErrorHandler;
