import PropTypes from 'prop-types';

import { Grid, Typography } from '@mui/material';

import { ContentContainerGrey } from '../CardContainer';

const TextWithLabel = ({ label, data, isSmall }) => {
  const labelFlex = isSmall ? 5 : 9;
  const dataFlex = isSmall ? 7 : 17;

  const columns = labelFlex + dataFlex;

  return (
    <ContentContainerGrey>
      <Grid container columns={columns} alignItems="flex-end">
        <Grid item flex={labelFlex}>
          <Typography variant="INPUT_LABEL_LARGE" color="light.neutral_grey.70p">
            {label}
          </Typography>
        </Grid>
        <Grid item flex={dataFlex}>
          <Typography variant="INPUT_TEXT" color="light.neutral_grey.70p">
            {data}
          </Typography>
        </Grid>
      </Grid>
    </ContentContainerGrey>
  );
};

TextWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func, PropTypes.element]).isRequired,
  isSmall: PropTypes.bool,
};

TextWithLabel.defaultProps = {
  isSmall: false,
};

export default TextWithLabel;
