import { createAction } from '@reduxjs/toolkit';

import { companySlice } from './reducer';

export const getMembers = createAction('company/getMembers');

export const updateMember = createAction('company/updateMember');

export const deleteMember = createAction('company/deleteMember');

export const { setMembers, setActiveMember, updateMembers, deleteMembers, updateMemberEntity, partialUpdateMember } =
  companySlice.actions;
