function pxToRem(value) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

const FONT_PRIMARY = 'Roboto, Public Sans, sans-serif';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 24, lg: 24 }),
  },
  h6: {
    fontWeight: 500,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 20, lg: 20 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontWeight: 400,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontWeight: 400,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1,
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
  // NOTE: new typografy styles
  H1: {
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.86rem',
  },
  H2: {
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.75rem',
    letterSpacing: '0.00938rem',
  },
  H3: {
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.24rem',
  },
  H4: {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.37375rem',
    letterSpacing: '0.03125rem',
  },
  H5: {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.37375rem',
    letterSpacing: '0.00625rem',
  },
  H5_CAPS: {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.5rem',
    letterSpacing: '0.025rem',
    textTransform: 'uppercase',
  },
  H6: {
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.75rem',
    letterSpacing: '0.00938rem',
  },
  TITLE1: {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.37375rem',
    letterSpacing: '0.00625rem',
  },
  TITLE2: {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.37375rem',
    letterSpacing: '0.00625rem',
  },
  TITLE3: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '0.93rem',
    letterSpacing: '0.025rem',
  },
  SUBTITLE1: {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.155rem',
    letterSpacing: '0.01063rem',
  },
  SUBTITLE2: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '0.93rem',
    letterSpacing: '0.025rem',
  },
  BODY1: {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.155rem',
    letterSpacing: '0.01063rem',
  },
  BODY2: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '0.99rem',
    letterSpacing: '0.025rem',
  },
  CAPTION: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '0.93rem',
    letterSpacing: '0.025rem',
  },
  CHIP: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '0.93rem',
    letterSpacing: '0.025rem',
  },
  BUTTON: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '0.93rem',
    letterSpacing: '0.025rem',
  },
  BUTTON_CAPS: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '0.93rem',
    letterSpacing: '0.025rem',
    textTransform: 'uppercase',
  },
  HELPER_TEXT: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '0.93rem',
    letterSpacing: '0.025rem',
  },
  INPUT_LABEL: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '0.93rem',
    letterSpacing: '0.025rem',
  },
  INPUT_LABEL_LARGE: {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.37375rem',
    letterSpacing: '0.00625rem',
  },
  INPUT_LABEL_SMALL: {
    fontSize: '0.625rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '0.75rem',
    letterSpacing: '0.025rem',
  },
  INPUT_TEXT: {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.155rem',
    letterSpacing: '0.01063rem',
  },
  MENU_ITEM: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '0.93rem',
    letterSpacing: '0.025rem',
  },
  MENU_SECTION: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.995rem',
    letterSpacing: '0.0625rem',
    textTransform: 'uppercase',
  },
  MENU_SELECT: {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.155rem',
    letterSpacing: '0.01063rem',
  },
  TAG: {
    fontSize: '0.625rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '0.625rem',
    leadingTrim: 'both',
    textEdge: 'cap',
  },
  TAG_CAPS: {
    fontSize: '0.625rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '0.625rem',
    leadingTrim: 'both',
    textEdge: 'cap',
    textTransform: 'uppercase',
  },
  PAGINATOR: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '0.93rem',
    letterSpacing: '0.025rem',
  },
  COUNTER: {
    fontSize: '0.5625rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '0.6975rem',
    letterSpacing: '0.025rem',
    leadingTrim: 'both',
    textEdge: 'cap',
  },
};

export default typography;
