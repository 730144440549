/* eslint-disable no-void */
import * as React from 'react';

import delay from 'src/functions/delay';

import { reducer, REMOVE_MESSAGE, SHOW_ALERT } from './reducer';

const initialState = {
  alerts: [],
};

const removeMEssageAfterDelay = async (dispatch, alert) => {
  if (typeof alert?.onShow === 'function') alert.onShow();
  await delay(5000);
  if (typeof alert?.onHide === 'function') alert.onHide();
  dispatch({
    type: REMOVE_MESSAGE,
  });
};

export const AlertsContext = React.createContext(initialState);

// eslint-disable-next-line import/no-mutable-exports
let alertsDispatch;

export const AlertsContextProvider = ({ children }) => {
  const [alerts, dispatchCTX] = React.useReducer(reducer, initialState);

  const dispatch = React.useCallback(action => {
    dispatchCTX(action);
    void removeMEssageAfterDelay(dispatchCTX, action.payload);
  }, []);

  alertsDispatch = dispatch;

  const state = React.useMemo(
    () => ({
      ...alerts,
      dispatch,
    }),
    [alerts, dispatch],
  );

  if (process.env.NODE_ENV !== 'production') {
    console.info('%c ---> alerts context <--- ', 'background: #89fb4c; color: #093793', alerts);
  }

  return <AlertsContext.Provider value={state}>{children}</AlertsContext.Provider>;
};

export const actions = {
  showAlert: data =>
    alertsDispatch({
      type: SHOW_ALERT,
      payload: data,
    }),
};
