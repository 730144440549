import moment from 'moment';

// TODO: check is need
const getCookies = () => {
  const cookies = {};

  if (typeof document !== 'undefined') {
    document.cookie.split(';').forEach(cookie => {
      const splittedCookie = cookie.split('=');
      const key = splittedCookie[0].replace(' ', '');

      // eslint-disable-next-line prefer-destructuring
      cookies[key] = splittedCookie[1];
    });
  }

  return cookies;
};

const removeCookie = (cookieName, { domain, path } = {}) => {
  let cookie = `${cookieName}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

  cookie += typeof domain !== 'undefined' ? `Domain=${domain};` : '';
  cookie += typeof path !== 'undefined' ? `Path=${path};` : '';

  document.cookie = cookie;
};

const setCookie = (
  cookieName,
  value,
  { domain, path, attributes } = {},
  expires = moment().add(5, 'years').toDate().toUTCString(),
) => {
  let cookie = `${cookieName}=${value}; Expires=${expires};`;

  cookie += typeof domain !== 'undefined' ? `Domain=${domain};` : '';
  cookie += typeof path !== 'undefined' ? `Path=${path};` : 'Path=/;';
  cookie += typeof attributes !== 'undefined' ? attributes : '';

  document.cookie = cookie;
};

export { getCookies, removeCookie, setCookie };
