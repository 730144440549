import { apiInstance } from 'src/functions/request';

export const getMembers = ({ companyId, take, skip }) =>
  apiInstance.get(`company/${companyId}/team-member`, {
    params: {
      ...(take ? { take } : {}),
      ...(skip ? { skip } : {}),
    },
  });

export const updateMember = ({ memberId, data }) => apiInstance.patch(`company/team-member/${memberId}`, data);

export const deleteMember = memberId => apiInstance.delete(`company/team-member/${memberId}`);
