import { call, put } from 'redux-saga/effects';

import * as companyActions from '../../company/actions';
import * as accessActions from '../actions';
import * as accessApi from '../api';

function* changeUserAccess({ payload }) {
  yield put(accessActions.setLoading(true));
  try {
    const response = yield call(accessApi.changeUserAccess, payload);

    yield put(companyActions.updateMemberEntity(response.data));
  } catch (error) {
    console.error(error);
  }
  yield put(accessActions.setLoading(false));
}

export default changeUserAccess;
