import PropTypes from 'prop-types';

import { Button, Stack, Typography } from '@mui/material';

import { IconArrowRight } from 'src/components_payroll/Icons';

const sxTxt = {
  color: 'light.primary.dark',
  cursor: 'pointer',
  '&.MuiButton-root:hover': {
    color: 'light.primary.shades.60p',
  },
  '& :disabled': {
    color: 'light.primary.shades.30p',
  },
};

const LinkButton = ({ routeTo, title, variant, openInNewTab, isArrowRight, isDisabled }) => {
  const arrowColor = isDisabled ? 'light.primary.shades.30p' : 'light.primary.shades.60p';

  return (
    <Button
      href={routeTo}
      target={openInNewTab ? '_blank' : '_self'}
      disabled={isDisabled}
      sx={{
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Stack direction="row" gap="0.5rem">
        <Typography variant={variant} sx={sxTxt}>
          {title}
        </Typography>
        {isArrowRight && <IconArrowRight color={arrowColor} />}
      </Stack>
    </Button>
  );
};

LinkButton.propTypes = {
  title: PropTypes.string.isRequired,
  routeTo: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool,
  isArrowRight: PropTypes.bool,
  isDisabled: PropTypes.bool,
  variant: PropTypes.oneOf(['CAPTION', 'BUTTON']),
};

LinkButton.defaultProps = {
  openInNewTab: false,
  variant: 'BUTTON',
  isArrowRight: false,
  isDisabled: false,
};

export default LinkButton;
