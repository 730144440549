import { CardsTooltip, CompanySwitcherTooltip } from 'src/pages/Member-HR/ChooseNewMemberType/OnBoard';

export default [
  {
    target: '[tour="team-new-offer-choose-company-btns"]',
    content: <CompanySwitcherTooltip />,
  },
  {
    target: '[tour="team-new-offer-cards-details"]',
    content: <CardsTooltip />,
    placement: 'left',
  },
];
