import { all, takeLatest } from 'redux-saga/effects';

import * as clientsActions from '../actions';

import getClients from './worker.getClients';
import updateCompany from './worker.updateCompany';

function* clientsSaga() {
  yield all([
    takeLatest([clientsActions.getClients.type], getClients),
    takeLatest([clientsActions.updateCompany.type], updateCompany),
  ]);
}

export default clientsSaga;
