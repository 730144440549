import { createAction } from '@reduxjs/toolkit';

import { clientsSlice } from './reducer';

export const getClients = createAction('clients/getClients');

export const updateCompany = createAction('clients/updateCompany');

export const { setLoading, setClients, setPage, setPageSize, setAgencyCompanyName, setTotal, setActiveClient, updateClient } =
  clientsSlice.actions;
