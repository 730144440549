import { Stack, Typography } from '@mui/material';

import { useSelector } from 'react-redux';

import { useMemo } from 'react';

import HelpBlock from 'src/onboard/components/HelpBlock';
import { ClickableSupportEmail, TextLink } from 'src/components_payroll/Texts';

import * as authSelectors from 'src/store/auth/selectors';

const settingsLegalEntityLink = '/member-admin/settings/legal-entities';
const settingsProjectLink = '/member-admin/settings/projects';

export const LegalEntityTooltip = () => {
  const user = useSelector(authSelectors.getUser);

  const isUserAdmin = useMemo(() => user?.role === 'hunter-admin', [user]);

  return (
    <Stack gap="1rem">
      {isUserAdmin && (
        <HelpBlock title="Edit Entity Details">
          Edit the entity and the related bank data <TextLink title="here" navigateTo={settingsLegalEntityLink} />.
        </HelpBlock>
      )}

      <HelpBlock title="Need More Banks?">
        Email us at <ClickableSupportEmail />
      </HelpBlock>
    </Stack>
  );
};

export const EmploymentDurationTooltip = () => (
  <Stack gap="1rem">
    <HelpBlock title="Add National Work Calendar">
      A work calendar is based on national holidays and weekends. Employee time reports will be automatically filled in based on this
      calendar.
    </HelpBlock>

    <HelpBlock title="Create Work Calendar">
      If your country isn&apos;t listed, contact <ClickableSupportEmail /> to add it.
    </HelpBlock>
  </Stack>
);

export const PaymentTooltip = () => {
  const user = useSelector(authSelectors.getUser);

  const isUserAdmin = useMemo(() => user?.role === 'hunter-admin', [user]);

  return (
    <Stack gap="1rem">
      <HelpBlock title="Change Currency">The currency is linked to the project&apos;s currency.</HelpBlock>

      {isUserAdmin && (
        <Typography variant="SUBTITLE1">
          To edit, click <TextLink title="here" navigateTo={settingsProjectLink} />
        </Typography>
      )}
    </Stack>
  );
};
