/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  title: '',
  body: '',
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openAlertDialog: (state, action) => {
      const { title, body } = action.payload;

      state.isOpen = true;
      state.title = title;
      state.body = body;
    },
    closeAlertDialog: (state, _payload) => {
      state.isOpen = false;
    },
  },
});

export default modalSlice.reducer;
