export default [
  {
    target: '[tour="team-link-btn"]',
    content: 'Offer you can find here',
    navigateTo: '/company/talents-pool/general',
    hideFooter: true,
  },
  {
    target: '[tour="add-new-member-btn"]',
    content: 'pls click here',
    navigateTo: '/member-hr/team/add/client',
    hideFooter: true,
  },
];
