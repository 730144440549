import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/v1/auth/`;

class AuthService {
  calendlyAuthenticate(calendlyId, userId) {
    return axios.post(`${API_URL}calendly-authenticate/${calendlyId}`, {
      userId,
    });
  }

  calendlyLogout(calendlyId) {
    return axios.delete(`${API_URL}calendly-logout/${calendlyId}`);
  }

  getGoogleAuthLink({ type, userId = '', token = '' }) {
    return axios.get(`${API_URL}google/link?type=${type}&userId=${userId}&token=${token}`).then(({ data }) => data);
  }

  googleAuthLogout() {
    return axios.delete(`${API_URL}google-auth/logout`).then(({ data }) => data);
  }

  signInWithGoogle(data) {
    return axios.post(`${API_URL}google`, data).then(response => {
      if (response.data.tokens.access.token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }

      return response;
    });
  }

  googleLogout(googleId) {
    return axios.delete(`${API_URL}google-calendar-logout/${googleId}`);
  }

  login(email, password) {
    return axios.post(`${API_URL}login`, {
      email,
      password,
    });
  }

  logout() {
    return axios.delete(`${API_URL}logout`);
  }

  register(position, firstName, lastName, email, password) {
    return axios.post(`${API_URL}register`, {
      position,
      firstName,
      lastName,
      email,
      password,
    });
  }

  changePassword(oldPassword, newPassword) {
    return axios.post(`${API_URL}change-password`, {
      oldPassword,
      newPassword,
    });
  }

  resetPassword(password, token, firstName, lastName) {
    return axios.post(`${API_URL}reset-password?token=${token}`, {
      password,
      firstName,
      lastName,
    });
  }

  passwordResetRequest(email) {
    return axios.post(`${API_URL}forgot-password`, {
      email,
    });
  }
}

export default new AuthService();
