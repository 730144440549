import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const Container = styled(Stack)`
  padding: 24px 16px;
  background: #ffffff;
  height: 556px;
  width: 375px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: 'none';
  }
`;
