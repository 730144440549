/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import authService from '../../services/auth.service';

const login = createAsyncThunk('login', async data => {
  const { email, password } = data;
  const response = await authService.login(email, password);

  return response.data.user;
});

const initialState = {
  loaded: false,
  isAuthenticated: false,
  user: null,
  employer: null,
  company: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => initialState,
    addUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.loaded = true;
    },
    updateUser: (state, action) => {
      const { payload } = action;

      state.user = { ...state.user, ...payload };
    },
    addEmployer: (state, action) => {
      state.employer = action.payload;
    },
    addCompany: (state, action) => {
      state.company = action.payload;
    },
    updateCompany: (state, action) => {
      state.company = { ...state.company, ...action.payload };
    },
    addToCompanyHuntflowAuth: (state, action) => {
      state.company.huntflowAuth = action.payload;
    },
    removeToCompanyHuntflowAuth: state => {
      state.company.huntflowAuth = null;
    },
    removeUser: state => {
      state.user = null;
      state.isAuthenticated = false;
    },
    setLoaded: state => {
      state.loaded = true;
    },
  },
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export default authSlice.reducer;
