import { all, takeLatest } from 'redux-saga/effects';

import * as constantsActions from '../actions';

import * as worker from './worker';

function* constantsSaga() {
  yield all([takeLatest([constantsActions.getCountries.type], worker.fetchCountries)]);
}

export default constantsSaga;
