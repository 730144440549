import { call, put } from 'redux-saga/effects';

import { actions } from 'src/contexts/alert/context';

import * as clientsActions from '../actions';
import * as clientsApi from '../api';

function* updateCompany({ payload }) {
  yield put(clientsActions.setLoading(true));
  try {
    const response = yield call(clientsApi.updateCompany, payload);

    yield put(clientsActions.updateClient(response.data));
    actions.showAlert({
      type: 'success',
      message: 'Client status updated successfully',
    });
  } catch (error) {
    console.error(error);
  } finally {
    yield put(clientsActions.setLoading(false));
  }
}

export default updateCompany;
