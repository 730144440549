import PropTypes from 'prop-types';

import { Button, Stack, Typography } from '@mui/material';

const sxTxt = {
  color: 'light.primary.dark',
  cursor: 'pointer',
  '&.MuiButton-root:hover': {
    color: 'light.primary.shades.60p',
    backgroundColor: 'inherit',
  },
  '& :disabled': {
    color: 'light.primary.shades.30p',
  },
};

const TextButton = ({ title, variant, Icon, onClick, isDisabled, padding }) => {
  const iconColor = isDisabled ? 'light.primary.shades.30p' : 'light.primary.shades.60p';

  return (
    <Button onClick={onClick} disabled={isDisabled} sx={{ padding, minWidth: 0 }}>
      <Stack direction="row" gap="0.5rem">
        <Typography variant={variant} sx={sxTxt}>
          {title}
        </Typography>
        {Icon && <Icon color={iconColor} />}
      </Stack>
    </Button>
  );
};

TextButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  padding: PropTypes.string,
  variant: PropTypes.oneOf(['CAPTION', 'BUTTON']),
};

TextButton.defaultProps = {
  Icon: null,
  variant: 'BUTTON',
  padding: '0',
  isDisabled: false,
  onClick: () => {},
};

export default TextButton;
