import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const TextButton = ({ onClick, title, startIcon, endIcon, size, component, buttonProps, textProps }) => (
  <Button
    color="primary"
    size={size}
    variant="text"
    component={component}
    onClick={onClick}
    sx={{
      '&:hover': {
        backgroundColor: 'transparent',
      },
      ...buttonProps,
    }}
    startIcon={startIcon}
    endIcon={endIcon}
  >
    <Typography variant="BUTTON" color="light.primary.dark" textTransform="none" {...textProps}>
      {title}
    </Typography>
  </Button>
);

TextButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  size: PropTypes.string,
  component: PropTypes.node,
  buttonProps: PropTypes.object,
  textProps: PropTypes.object,
};

export default TextButton;
