/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countries: [],
};

export const constantsSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
  },
});

export default constantsSlice.reducer;
