import { Typography } from '@mui/material';

import ChatItem from 'src/components/chat/ChatItem/ChatItem';

import { Container } from './ChatList.style';

const ChatList = ({ user, chats, setChatId }) => {
  const chatsArray = Object.values(chats).sort((a, b) => {
    if (a.messages.length && b.messages.length) return new Date(b.messages[0].timestamp) - new Date(a.messages[0].timestamp);

    return a.createdAt - b.createdAt;
  });

  return (
    <Container spacing={1}>
      {chatsArray.length ? (
        chatsArray.map((chat, i) => <ChatItem key={i} user={user} chat={chat} onClick={() => setChatId(chat.id)} />)
      ) : (
        <Typography sx={{ textAlign: 'center' }}>You don`t have any active chats now (</Typography>
      )}
    </Container>
  );
};

export default ChatList;
