import PropTypes from 'prop-types';

import { LoadingButton } from '@mui/lab';
import { Typography, useTheme } from '@mui/material';

import { IconArrowRight } from 'src/components_payroll/Icons';

import spinnerButtonStyles from './spinnerButton.styles';

const SpinnerButton = ({ title, isDisabled, isLoading, onClick, isEndIcon, EndIcon, mode, sx, tour }) => {
  const theme = useTheme();

  const { sxBtn } = spinnerButtonStyles(theme)[mode];

  const arrowColor = isDisabled ? 'light.primary.shades.30p' : 'light.primary.shades.60p';

  const props = onClick ? { onClick } : { type: 'submit' };

  return (
    <LoadingButton
      fullWidth
      size="medium"
      variant="contained"
      disabled={isDisabled}
      loading={isLoading}
      endIcon={isEndIcon && <EndIcon color={mode === 'red' ? 'light.error.shades.60p' : arrowColor} />}
      sx={{ ...sxBtn, ...sx }}
      {...props}
      tour={tour}
    >
      <Typography variant="BUTTON" whiteSpace="nowrap" textTransform="none">
        {title}
      </Typography>
    </LoadingButton>
  );
};

SpinnerButton.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]).isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isEndIcon: PropTypes.bool,
  EndIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  mode: PropTypes.oneOf(['dark', 'light', 'red']),
};

SpinnerButton.defaultProps = {
  isDisabled: false,
  isLoading: false,
  isEndIcon: true,
  EndIcon: IconArrowRight,
  mode: 'dark',
};

export default SpinnerButton;
