import React from 'react';
import { Input as InputWrapped } from 'react-chat-elements';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { IconButton } from '@mui/material';

import { StyledBox } from './Input.style';

const Input = ({ inputReferance, photoInputReferance, inputChangeHandler, sendHandler, photoHandler, photoChangeHandler }) => (
  <StyledBox>
    <InputWrapped
      placeholder="Type here..."
      referance={inputReferance}
      multiline={false}
      onKeyPress={e => {
        if (e.shiftKey && e.charCode === 13) {
          return;
        }

        if (e.charCode === 13) {
          e.preventDefault();

          sendHandler();
        }
      }}
      onChange={inputChangeHandler}
      rightButtons={
        <IconButton onClick={photoHandler}>
          <AttachFileIcon />
        </IconButton>
      }
      maxHeight={200}
    />

    <input type="file" style={{ display: 'none' }} ref={photoInputReferance} onChange={photoChangeHandler} />
  </StyledBox>
);

export default Input;
