import HelpBlock from '../components/HelpBlock';

export default [
  {
    target: '[tour="hunter-settings-btn"]',
    content: <HelpBlock title="Enter Company Details">Start here</HelpBlock>,
    navigateTo: '/company/settings/general',
    disableBeacon: true,
    hideFooter: true,
  },
  {
    target: '[tour="projects-settings"]',
    content: <HelpBlock title="Select Project" />,
    navigateTo: '/member-admin/settings/projects',
    hideFooter: true,
  },
  {
    target: '[tour="add-new-project-btn"]',
    content: <HelpBlock title="Click here" />,
    hideFooter: true,
  },
  {
    target: '[tour="project-sidebar"]',
    content: (
      <HelpBlock title="Specify Currency">
        In contracts, we&apos;ll automatically use the same currency as in the project. It can be changed only here.
      </HelpBlock>
    ),
    placement: 'left',
    hideBackButton: true,
  },
];
