import { createAction } from '@reduxjs/toolkit';

import { docsSlice } from './reducer';

export const uploadFile = createAction('docs/upload');

export const deleteFile = createAction('docs/delete');

export const createDoc = createAction('docs/createDoc');

export const getDocs = createAction('docs/getDocs');

export const getSelectDocs = createAction('docs/getSelectDocs');

export const deleteDoc = createAction('docs/deleteDoc');

export const updateDocument = createAction('docs/updateDocument');

export const getDocsByIds = createAction('docs/getDocsByIds');

export const getSignedDocsForVacancy = createAction('docs/getSignedDocsForVacancy');

export const updateCompanyDocs = createAction('docs/updateCompanyDocs');

export const getCompanyDocIds = createAction('docs/getCompanyDocIds');

export const {
  setActiveDescription,
  setActiveFile,
  setActiveLabel,
  setActiveRequired,
  resetActiveData,
  setDocs,
  addNewDoc,
  setPage,
  setPageSize,
  setTotalDocs,
  setActiveDoc,
  removeDoc,
  updateDoc,
  setLoading,
  setSelectDocs,
  setSelectDocsLoading,
  setUploadDocs,
  setUploadDocsLoading,
  setSignedDocs,
  addSignedDoc,
  setCompanyDocIds,
} = docsSlice.actions;
