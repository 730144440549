import { call, put } from 'redux-saga/effects';

import * as companyActions from '../actions';
import * as companyApi from '../api';

function* deleteMember({ payload }) {
  try {
    yield call(companyApi.deleteMember, payload);
    yield put(companyActions.deleteMembers(payload));
  } catch (error) {
    console.error(error);
  }
}

export default deleteMember;
