import { call, put, select } from 'redux-saga/effects';

import * as docsApi from '../api';
import * as docsActions from '../actions';

function* getCompanyDocIds() {
  try {
    const { activeClient } = yield select(store => store.clients);
    const response = yield call(docsApi.getCompanyDocIds, activeClient?.id);

    yield put(docsActions.setCompanyDocIds(response.data));
  } catch (error) {
    console.error(error);
  }
}

export default getCompanyDocIds;
