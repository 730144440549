import { all, takeLatest } from 'redux-saga/effects';

import * as companyActions from '../actions';

import getMembers from './worker.getMembers';
import updateMember from './worker.updateMember';
import deletemember from './worker.deletemember';

function* clientsSaga() {
  yield all([
    takeLatest([companyActions.getMembers.type], getMembers),
    takeLatest([companyActions.updateMember.type], updateMember),
    takeLatest([companyActions.deleteMember.type], deletemember),
  ]);
}

export default clientsSaga;
