import { styled, keyframes } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

const appear = keyframes`
    from {
        left: -500px
    }
    to {
        left: 20px;
    }
`;

export const StyledStack = styled(Stack)`
  width: 40%;
  max-width: 300px;
  position: fixed;
  top: 20px;
  left: 20px;
  animation: ${appear} 0.3s ease-in-out;
  z-index: 1201;
`;

export const StyledAlert = styled(Alert)`
  word-break: break-word;
  max-height: 300px;
  overflow: auto;
`;
