import { createSelector } from '@reduxjs/toolkit';

const getAuthEntity = ({ auth }) => auth;

export const getAuth = createSelector(getAuthEntity, auth => auth);

export const getLoaded = createSelector(getAuthEntity, auth => auth.loaded);

export const getUser = createSelector(getAuthEntity, auth => auth.user);

export const getCompany = createSelector(getAuthEntity, auth => auth.company);
