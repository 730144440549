import { call, put, select } from 'redux-saga/effects';

import * as companyActions from '../actions';
import * as companyApi from '../api';

function* getClients() {
  try {
    const { company, user } = yield select(store => store.auth);
    const companyId = company ? company.id : user?.company.id;

    const response = yield call(companyApi.getMembers, { companyId });

    yield put(companyActions.setMembers(response.data.users));
  } catch (error) {
    console.error(error);
  }
}

export default getClients;
