import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/v1/auth`;

class RegistrationService {
  talentAccount({ firstName, lastName, email, password }) {
    return axios.post(`${API_URL}/register-talent`, {
      firstName,
      lastName,
      email,
      password,
    });
  }

  register(position, firstName, lastName, email, password) {
    return axios.post(`${API_URL}/register`, {
      position,
      firstName,
      lastName,
      email,
      password,
    });
  }
}

export default new RegistrationService();
