import { ClickableSupportEmail } from 'src/components_payroll/Texts';

import HelpBlock from '../components/HelpBlock';

export default [
  {
    target: '[tour="hunter-settings-btn"]',
    content: <HelpBlock title="Enter Company Details">Start here</HelpBlock>,
    disableBeacon: true,
    hideFooter: true,
  },
  {
    target: '[tour="legal-entities-setting"]',
    content: <HelpBlock title="Choose Legal Entity" />,
    navigateTo: '/member-admin/settings/legal-entities',
    hideFooter: true,
  },
  {
    target: '[tour="add-new-legal-entity-btn"]',
    content: <HelpBlock title="Click here" />,
    hideFooter: true,
  },
  {
    target: '[tour="legal-entity-sidebar"]',
    content: (
      <HelpBlock title="Company bank account">
        To add more bank accounts, email <ClickableSupportEmail />
      </HelpBlock>
    ),
    placement: 'left-end',
    hideBackButton: true,
  },
];
