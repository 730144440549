import { put, select } from 'redux-saga/effects';

import * as accessActions from '../actions';

function* ownerWathcer() {
  try {
    const { user, company } = yield select(store => store.auth);

    if (user && company && company.userID === user.id) {
      yield put(accessActions.setIsOwner(true));
    } else {
      yield put(accessActions.setIsOwner(false));
    }
  } catch (error) {
    console.error(error);
  }
}

export default ownerWathcer;
