export default {
  email: 'ic:outline-email',
  check: 'material-symbols:done',
  alertCircle: 'mdi:alert-circle-outline',
  alertTriangle: 'lucide:alert-triangle',
  clock: 'mdi:clock-time-four-outline',
  home: 'lucide:home',
  clipboard: 'lucide:clipboard',
  dollar: 'lucide:dollar-sign',
  calendar: 'lucide:calendar',
  file: 'lucide:file',
  file_plus: 'lucide:file-plus',
  arrowRight: 'tabler:arrow-right',
  arrowLeft: 'tabler:arrow-left',
  smileMeh: 'uil:meh',
  smile: 'hugeicons:smile',
  tool: 'streamline:interface-setting-wrench-crescent-tool-construction-tools-wrench-setting-edit-adjust',
  phone: 'ph:phone',
  user: 'lucide:user',
  users: 'lucide:users',
  creditCard: 'lucide:credit-card',
  sun: 'lucide:sun',
  feather: 'lucide:feather',
  login: 'material-symbols:login-rounded',
  logout: 'material-symbols:logout',
  download: 'material-symbols:download',
  upload: 'material-symbols:upload',
  eyeOpen: 'eva:eye-fill',
  eyeClose: 'eva:eye-off-fill',
  bookOpen: 'ion:book-outline',
  activity: 'tabler:activity',
  star: 'iconamoon:star-light',
  gift: 'mdi:gift-outline',
  edit3: 'iconamoon:edit-light',
  plus: 'ic:round-plus',
  chevronUp: 'tabler:chevron-up',
  chevronDown: 'tabler:chevron-down',
  moreVertical: 'lucide:more-vertical',
  list: 'lucide:list',
  search: 'lucide:search',
  trello: 'lucide:trello',
  rotate: 'lucide:rotate-cw',
  server: 'lucide:server',
  x: 'iconamoon:close-light',
  chevronsRight: 'tabler:chevrons-right',
  loader: 'nonicons:loading-16',
  infoCircle: 'material-symbols:info-outline',
  saveDisk: 'fluent:save-24-regular',
  folder: 'ic:outline-folder',
  database: 'material-symbols:database-outline',
  settings: 'ci:settings',
  heart: 'ant-design:heart-outlined',
  messageCircle: 'tabler:message-circle-2',
  monitor: 'lucide:monitor',
  carbon: 'carbon:stop-outline',
  headphone: 'ri:headphone-line',
  software: 'icon-park-outline:disk',
  expandLess: 'material-symbols:expand-less',
  expendMore: 'material-symbols:expand-more',
  arrowForward: 'mdi:arrow-forward',
  arrowDownLine: 'solar:double-alt-arrow-down-line-duotone',
  arrowUpLine: 'solar:double-alt-arrow-up-line-duotone',
  export: 'pajamas:export',
  smileNone: 'gg:smile-none',
  resendEmail: 'streamline:mail-send-email-message',
  like: 'iconamoon:like-thin',
  arrowDown: 'solar:alt-arrow-down-line-duotone',
  arrowUp: 'solar:alt-arrow-up-line-duotone',
  copyLink: 'material-symbols-light:link',
  trash: 'ph:trash-bold',
  vacation: 'icon-park-outline:vacation',
  briefcase: 'eva:briefcase-outline',
  externalLink: 'nimbus:external-link',

  // NOTE: myFinance
  timesheets: 'mdi:clock-time-eight-outline',
  requests: 'ep:chat-dot-round',
  sickLeaves: 'carbon:stop-outline',
  payouts: 'ph:currency-dollar',

  invoice: 'akar-icons:paper',
  dollarInvoice: 'fa:dollar',
  doubleArrowDown: 'ic:round-keyboard-double-arrow-down',
  doubleArrowUp: 'ic:round-keyboard-double-arrow-up',
  refresh: 'tabler:refresh',
  cornerRightDown: 'humbleicons:corner-right-down',
  television: 'mdi:television',
  shield: 'material-symbols:shield-outline',
  revert: 'grommet-icons:revert',
  bambooHR: 'arcticons:bamboohr',
  question: 'fluent:question-circle-24-regular',
  cancel: 'mdi:cancel',

  network: 'zondicons:network',
  linkedin: 'uit:linkedin-alt',
};
