import { combineReducers } from 'redux';

import access from './access/reducer';
import auth from './auth/reducer';
import chat from './chat/reducer';
import clients from './clients/reducer';
import company from './company/reducer';
import constants from './constants/reducer';
import docs from './docs/reducer';
import kanban from './kanban/reducer';
import modal from './modal/reducer';

const appReducer = combineReducers({
  auth,
  chat,
  kanban,
  docs,
  clients,
  access,
  company,
  modal,
  constants,
});

export default appReducer;
