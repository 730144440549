import { Box, Stack, styled } from '@mui/material';

const CardContainer = styled(Box)(({ theme }) => ({
  borderRadius: '0.5rem',
  border: `1px solid ${theme.palette.light.neutral_grey['10p']}`,
  background: `${theme.palette.light.primary.contrast}`,
  padding: '1.5rem 1.5rem 2rem 1.5rem',
}));

export const ContentContainerGrey = styled(Stack)(({ theme }) => ({
  gap: '0.5rem',
  padding: '1rem',
  borderRadius: '0.5rem',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.light.neutral_grey['5p'],
  backgroundColor: theme.palette.light.neutral_grey['2p'],
}));

export default CardContainer;
