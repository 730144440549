export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const SHOW_ALERT = 'SHOW_ALERT';

export const reducer = (state, action) => {
  if (action.type === SHOW_ALERT) {
    return {
      alerts: [action.payload, ...state.alerts],
    };
  }

  if (action.type === REMOVE_MESSAGE) {
    return {
      alerts: [...state.alerts.slice(0, -1)],
    };
  }

  return state;
};
