import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/v1`;

class OrgChatService {
  getCompanyOrgChatData(companyId) {
    return axios.get(`${API_URL}/orgChat/company/${companyId}/all`).then(({ data }) => data);
  }

  getCompanyDepartments(companyId) {
    return axios.get(`${API_URL}/orgChat/company/${companyId}/departments`).then(({ data }) => data);
  }

  createOrUpdateDepartment(companyId, data) {
    return axios.post(`${API_URL}/orgChat/company/${companyId}/department/createOrUpdate`, data).then(({ data }) => data);
  }

  deleteDepartment(companyId, departmentId) {
    return axios.delete(`${API_URL}/orgChat/company/${companyId}/department/${departmentId}`).then(({ data }) => data);
  }

  setUserDepartment(companyId, departmentId = null, userId) {
    return axios.patch(`${API_URL}/orgChat/company/${companyId}/department/${departmentId}/user/${userId}`).then(({ data }) => data);
  }

  setUserLead(companyId, userId, leadId = null) {
    return axios.patch(`${API_URL}/orgChat/company/${companyId}/user/${userId}/lead/${leadId}`).then(({ data }) => data);
  }

  setUserLeadStatus(companyId, userId, isLead) {
    return axios.post(`${API_URL}/orgChat/company/${companyId}/user/${userId}/lead/status`, { isLead }).then(({ data }) => data);
  }

  getCompanyUsersToBeLeads(companyId) {
    return axios.get(`${API_URL}/orgChat/company/${companyId}/users/to-be-leads`).then(({ data }) => data);
  }
}

export default new OrgChatService();
