import { call, select } from 'redux-saga/effects';

import * as docsApi from '../api';

function* updateCompanyDocs({ payload }) {
  try {
    const { company } = yield select(store => store.auth);

    yield call(docsApi.updateCompanyDocs, {
      documents: payload,
      companyId: company?.id,
    });
  } catch (error) {
    console.error(error);
  }
}

export default updateCompanyDocs;
