import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Box, Stack } from '@mui/material';
import moment from 'moment';

import { VACANCY_STATUS_TO_LABEL } from 'src/constants/chat';
import * as authSelectors from 'src/store/auth/selectors';

import { StyledBox, Text, Container, SentIcon, ReadIcon, Label, UnreadAmount, DateWrapper } from './ChatItem.style';

const ChatItem = ({ chat, onClick }) => {
  const currentUser = useSelector(authSelectors.getUser);
  const [lastUser, setLastUser] = useState({
    firstName: '',
    lastName: '',
  });
  const lastMessage = chat.messages[0];

  const getChatLabel = () => {
    const { status } = chat.vacancy;

    return <Label color={VACANCY_STATUS_TO_LABEL[status]?.color}>{VACANCY_STATUS_TO_LABEL[status]?.value}</Label>;
  };

  const getUnreadAmount = () => {
    const unreadMessagesCount = chat.messages.filter(message => message.status === 'sent' && message.userId !== currentUser.id).length;

    if (unreadMessagesCount) {
      return <UnreadAmount>{unreadMessagesCount}</UnreadAmount>;
    }

    return null;
  };

  getUnreadAmount();

  const getMessageLabel = () => {
    if (lastMessage?.type === 'text') return lastMessage.text;
    if (lastMessage?.type === 'photo') return `File ${lastMessage.value}`;

    return 'No messages';
  };

  useEffect(() => {
    chat.members.forEach(member => {
      if (member.userId === lastMessage?.userId) {
        setLastUser(member.user);
      }
    });
  }, [chat, lastMessage?.userId]);

  return (
    <Container onClick={onClick}>
      <Grid container>
        <Grid item xs={9}>
          <Stack direction="column">
            <StyledBox>
              {getChatLabel()}
              {chat.vacancy.role.skill}
            </StyledBox>
            <StyledBox spacing="0.17px">{`${lastUser.firstName} ${lastUser.lastName}`}</StyledBox>
            <Box>
              <Text noWrap>{getMessageLabel()}</Text>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={1}>
          <Stack direction="column">{lastMessage?.status === 'read' ? <ReadIcon /> : <SentIcon />}</Stack>
        </Grid>
        <DateWrapper item xs={2}>
          <StyledBox spacing="0.17px" component="span">
            {moment(lastMessage?.timestamp).format('HH:mm')}
          </StyledBox>
          {getUnreadAmount()}
        </DateWrapper>
      </Grid>
    </Container>
  );
};

export default ChatItem;
