import { EmploymentDurationTooltip, LegalEntityTooltip, PaymentTooltip } from 'src/pages/Member-HR/Offer/OnBoard/Step3';

export default [
  {
    target: '[tour="new-offer-step-3-legal-entity"]',
    content: <LegalEntityTooltip />,
    placement: 'left',
  },
  {
    target: '[tour="new-offer-step-3-employment-duration"]',
    content: <EmploymentDurationTooltip />,
    placement: 'left',
  },
  {
    target: '[tour="new-offer-step-3-payment"]',
    content: <PaymentTooltip />,
    placement: 'right',
  },
];
