export default theme => ({
  dark: {
    sxBtn: {
      height: '100%',
      width: '100%',
      padding: '0.5rem 1.38rem',
      boxShadow: 'none',
      borderRadius: '0.25rem',
      background: theme.palette.light.primary.main,
      '&:hover': {
        background: theme.palette.light.primary.shades['115p'],
      },
      '&.Mui-disabled': {
        color: theme.palette.light.primary.shades['20p'],
        backgroundColor: theme.palette.light.primary.shades['50p'],
      },
    },
  },
  light: {
    sxBtn: {
      height: '100%',
      width: '100%',
      padding: '0.5rem 1.38rem',
      boxShadow: 'none',
      borderRadius: '0.25rem',
      backgroundColor: theme.palette.light.primary.shades['10p'],
      borderWidth: '1px',
      borderStyle: 'solid',
      color: theme.palette.light.primary.main,
      borderColor: theme.palette.light.primary.shades['40p'],
      '&:hover': {
        color: theme.palette.light.primary.dark,
        borderColor: theme.palette.light.primary.shades['60p'],
        backgroundColor: theme.palette.light.primary.shades['30p'],
      },
      '&.Mui-disabled': {
        color: theme.palette.light.primary.shades['30p'],
        borderColor: theme.palette.light.primary.shades['20p'],
        backgroundColor: theme.palette.light.primary.shades['8p'],
      },
    },
  },
  red: {
    sxBtn: {
      height: '100%',
      width: '100%',
      padding: '0.5rem 1.38rem',
      boxShadow: 'none',
      borderRadius: '0.25rem',
      backgroundColor: theme.palette.light.error.shades['12p'],
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.light.error.shades['20p'],
      color: theme.palette.light.error.light,
      '&:hover': {
        color: theme.palette.light.error.main,
        borderColor: theme.palette.light.error.shades['40p'],
        backgroundColor: theme.palette.light.error.shades['30p'],
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.light.error.shades['10p'],
        borderColor: theme.palette.light.error.shades['12p'],
        color: theme.palette.light.error.shades['40p'],
      },
    },
  },
});
