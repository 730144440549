import PropTypes from 'prop-types';

import { MenuItem as MenuItemMUI, Stack, Typography } from '@mui/material';

export const menuItemProps = {
  onClick: PropTypes.func,
  Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.oneOf([null])]),
  iconColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  isDisabled: PropTypes.bool,
};

const menuItemDefaultProps = {
  Icon: null,
  iconColor: 'light.error.shades.60p',
  titleColor: 'light.neutral_grey.70p',
  onClick: () => {},
  isDisabled: false,
};

const menuItem = handleClose => {
  const Wrapper = ({ onClick, Icon, iconColor, title, titleColor, isDisabled }) => {
    const handleClick = () => {
      onClick();
      handleClose();
    };

    return (
      <MenuItemMUI onClick={handleClick} disabled={isDisabled} key={title}>
        <Stack direction="row" gap="0.5rem">
          {Icon && <Icon color={iconColor} />}
          <Typography variant="BODY2" color={titleColor}>
            {title}
          </Typography>
        </Stack>
      </MenuItemMUI>
    );
  };

  Wrapper.propTypes = menuItemProps;
  Wrapper.defaultProps = menuItemDefaultProps;

  return Wrapper;
};

export default menuItem;
