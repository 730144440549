import { lazy, Suspense } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import Alert from 'src/components/Alert/Alert';
import ScrollToTop from 'src/components/ScrollToTop';
import ThemeProvider from 'src/theme';

import ChatBox from './components/chat';
import AlertDialogGlobal from './components/Dialog/AlertDialogGlobal';
import { OnBoard } from './onboard';

const Router = lazy(() => import('src/routes'));

const App = () => (
  <ThemeProvider>
    <ScrollToTop />
    <Suspense
      fallback={
        <Stack alignItems="center" justifyContent="center" sx={{ height: '100vh' }}>
          <CircularProgress />
        </Stack>
      }
    >
      <OnBoard>
        <Router />
      </OnBoard>
    </Suspense>
    <ChatBox />
    <Alert />
    <AlertDialogGlobal />
  </ThemeProvider>
);

export default App;
