import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import { actions } from 'src/contexts/alert/context';

const supportEmail = process.env.SUPPORT_EMAIL || 'info@juggl.co';

const ClickableSupportEmail = ({ variant }) => {
  const onClickEmail = email => {
    navigator.clipboard.writeText(email).then(() => {
      actions.showAlert({
        type: 'success',
        message: 'Mail copied',
      });
    });
  };

  return (
    <Typography variant={variant} color="light.primary.main" onClick={onClickEmail} sx={{ cursor: 'pointer' }}>
      {supportEmail}
    </Typography>
  );
};

ClickableSupportEmail.propTypes = {
  variant: PropTypes.string,
};

ClickableSupportEmail.defaultProps = {
  variant: 'SUBTITLE1',
};

export default ClickableSupportEmail;
