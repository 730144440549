import { call, put } from 'redux-saga/effects';

import * as docsApi from '../api';
import * as docsActions from '../actions';

function* deleteFile({ payload }) {
  try {
    yield call(docsApi.uploadFile, payload);
    yield put(docsActions.setActiveFile(''));
  } catch (error) {
    console.error(error);
  }
}

export default deleteFile;
