import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  padding: 24px 16px;
  background: #ffffff;
  height: 488px;
  width: 375px;
  max-width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;

  &::-webkit-scrollbar {
    display: none;
  }
`;
