import { createContext } from 'react';

export const defaultValue = {
  setTour: () => {},
  helpers: {
    close: () => {},
    go: () => {},
    info: () => {},
    next: () => {},
    open: () => {},
    prev: () => {},
    reset: () => {},
    skip: () => {},
  },
};

export const OnBoardContext = createContext(defaultValue);
