import { call, put } from 'redux-saga/effects';

import * as authActions from '../actions';
import * as authApi from '../api';

function* fetchUser() {
  try {
    const response = yield call(authApi.getUSer);

    yield put(authActions.addUser(response.data.user));
  } catch (error) {
    yield put(authActions.setLoaded());
  }
}

export default fetchUser;
