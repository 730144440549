import { createAction } from '@reduxjs/toolkit';

import { authSlice } from './reducer';

export const getUser = createAction('auth/getUser');

export const {
  logout,
  addUser,
  updateUser,
  addEmployer,
  addCompany,
  updateCompany,
  addToCompanyHuntflowAuth,
  removeToCompanyHuntflowAuth,
  setLoaded,
} = authSlice.actions;
