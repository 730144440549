import { Stack } from '@mui/material';

import HelpBlock from 'src/onboard/components/HelpBlock';
import { ClickableSupportEmail } from 'src/components_payroll/Texts';

export const PolicyTooltip = () => (
  <Stack gap="1rem">
    <HelpBlock title="Create Policy">
      If you need a customized benefits policy, email us at <ClickableSupportEmail />.
    </HelpBlock>
  </Stack>
);
