import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

const useAction = action => {
  const dispatch = useDispatch();

  return useMemo(
    () =>
      (...args) =>
        dispatch(action(...args)),
    [action, dispatch],
  );
};

export default useAction;
