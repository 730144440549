import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import { Box, Typography, Grid } from '@mui/material';
import { MdDone, MdDoneAll } from 'react-icons/md';

export const StyledBox = styledMUI(Box)`
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: ${({ spacing }) => spacing || '0.15px'};
  color: rgba(0, 0, 0, 0.87);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Text = styledMUI(Typography)`
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.6);
`;

export const Container = styledMUI(Box)`
  padding: 16px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.04);
  cursor: pointer;
`;

export const SentIcon = styledMUI(MdDone)`
  color: #9777F2;
`;

export const ReadIcon = styledMUI(MdDoneAll)`
  color: #9777F2;
`;

export const Label = styled.span`
  color: #fff;
  background-color: ${({ color }) => color || '#ED6C02'};
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
  margin-right: 4px;
`;

export const UnreadAmount = styled.span`
  font-size: 13px;
  letter-spacing: 0.16px;
  color: #ffffff;
  padding: 3px 8px;
  display: inline-block;
  background: #9777f2;
  border-radius: 16px;
`;

export const DateWrapper = styledMUI(Grid)`
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  align-items: flex-end;
`;
