import { Alert, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

import { removeCookie, setCookie } from 'src/functions/cookies';

class ErrorHandlerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      isRootUser: false,
      showMaintenanceAlert: false,
    };
  }

  componentDidMount() {
    this.handleRootParam();
    window.addEventListener('message', this.messageHandler);
  }

  componentDidCatch(error) {
    console.error(error);
    this.setState({ hasError: true });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler);
  }

  handleRootParam() {
    const url = new URLSearchParams(window.location.search);
    const root = url.get('root');

    if (root === 'true' || root === '1') {
      this.setState({ isRootUser: true });
      setCookie('root', 'true');
    } else {
      removeCookie('root', {
        domain: window.location.hostname,
        path: '/',
      });
    }
  }

  messageHandler = event => {
    const { status } = event.data;
    const { isRootUser } = this.state;

    if ([501, 502, 503, 504].includes(status) && !isRootUser) {
      window.location.href = `${window.location.protocol}//${window.location.host}/maintenance.html`;
    } else if ([501, 502, 503, 504].includes(status)) {
      this.setState({ showMaintenanceAlert: true });
      setTimeout(() => {
        this.setState({ showMaintenanceAlert: false });
      }, 3000);
    } else if (status === 401 && window.location.pathname !== '/v1/login') {
      removeCookie('jwt', {
        domain: window.location.hostname,
        path: '/',
      });
      removeCookie('jwt_refresh', {
        domain: window.location.hostname,
        path: '/',
      });

      window.location.href = `${window.location.protocol}//${window.location.host}/v1/login`;
    }
  };

  render() {
    const { hasError, isRootUser, showMaintenanceAlert } = this.state;
    const { children } = this.props;

    if (!hasError || isRootUser) {
      return (
        <>
          {showMaintenanceAlert ? (
            <Alert
              severity="error"
              sx={{
                position: 'absolute',
                top: 10,
                left: 10,
                width: '40%',
                zIndex: 10001,
              }}
            >
              We are on maintenance
            </Alert>
          ) : null}

          {children}
        </>
      );
    }

    return (
      <Dialog open aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">We are sorry</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Something went wrong, try to reload page or contact our support
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}

export default ErrorHandlerWrapper;
