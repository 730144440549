import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/v1`;

class NotifySettingsService {
  createOrUpdate(data) {
    return axios.put(`${API_URL}/notify-settings/createOrUpdate`, data).then(({ data }) => data);
  }

  deleteById(settingId) {
    return axios.delete(`${API_URL}/notify-settings/${settingId}`);
  }

  getAllByCompanyAndType(companyId, type) {
    return axios.get(`${API_URL}/notify-settings/company/${companyId}?type=${type}`).then(({ data }) => data);
  }
}

export default new NotifySettingsService();
